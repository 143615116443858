import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Chip } from '@mui/material';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { COLORS } from '../../../assets/constants/theme';
import { useInput } from 'react-admin';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { useSelector } from 'react-redux';
import { useRequiredSchema } from '../../../Hooks/useRequiredSchema';
const InputPillsSelect = ({
  choices,
  withText,
  required,
  approvedFields,
  textTransform,
  title,
  subtitle,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  featured,
  info,
  validate,
  ...rest
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const { isRequiredSchema } = useRequiredSchema();

  const { watch, setValue, getValues } = useFormContext();
  const {
    id,
    field: openField,
    fieldState,
  } = useInput({
    source,
    validate: [...isRequiredSchema(source)],
  });
  const [categoryPicked, setCategoryPicked] = useState(undefined);
  const handlerSetValue = (value) => {
    if (withText) {
      if (value?.text === categoryPicked) {
        setCategoryPicked(undefined);
        openField.onChange(null);
      } else {
        setCategoryPicked(value.text);
        fieldChange(value);
      }
    } else {
      if (value === categoryPicked) {
        setCategoryPicked(undefined);
        openField.onChange(null);
      } else {
        setCategoryPicked(value);
        fieldChange(value);
      }
    }
  };
  const fieldChange = (value) => {
    if (!info && !withText) {
      openField.onChange(value);
    } else if (withText) {
      openField.onChange(value.text);
    } else {
      openField.onChange({ ...openField.value, category: value });
    }
  };
  useEffect(() => {
    if (info) {
      openField.onChange(info?.home?.featured_categories[0]);
      setCategoryPicked(info?.home?.featured_categories[0].category);
      return;
    }
    setCategoryPicked(openField?.value);
  }, []);
  
  return (
    <>
      {title && (
        <InputTitle
          required={required}
          textTransform={textTransform}
          sx={{
            // marginTop: '25px',
            display: !watchField
              ? 'block'
              : watch(watchField) === 'true' ||
                  approvedFields?.includes(watch(watchField))
                ? 'block'
                : 'none',
          }}
        >
          {title}
        </InputTitle>
      )}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 10,
          marginTop: withText ? '0px' : '20px',
        }}
      >
        {choices &&
          choices.map((choice, index) => {
            return (
              <Chip
                sx={{
                  width: 'auto',
                  padding: '10px',
                  color: categoryPicked === choice?.[optionValue] ? palette.fontColor : '#000000',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor: withText
                    ? categoryPicked === choice.text
                      ? palette?.primary
                      : COLORS.forms.pills.background
                    : categoryPicked === choice
                      ? palette?.primary
                      : COLORS.forms.pills.background,
                  ':hover': {
                    backgroundColor: palette?.primary,
                    color: 'white',
                  },
                }}
                key={index}
                onClick={() => handlerSetValue(choice)}
                label={withText ? choice.text : choice}
                //   disabled={valores[source] === choice.id}
              />
            );
          })}
      </div>
    </>
  );
};

export default InputPillsSelect;
