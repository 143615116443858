import { IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { handleDayError } from './functions';
import styles from './styles/daysSelector.module.css';
import { IoIosArrowForward } from 'react-icons/io';
import { IoIosArrowBack } from 'react-icons/io';
import React, { memo, useEffect, useRef, useState } from 'react';

const styledDates = {
  fontSize: '.9em',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#D9D9D9',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  width: '70px',
  height: '70px',
};


const DaysSelector = (props) => {
  const palette = useSelector((state) => state?.palette?.palette);

  const {
    setDayPicked,
    days,
    dayPicked,
    setScrollPosition,
    scrollPosition
  } = props;
  const showArrows = days.length > 9;
  const scrollContainerRef = useRef(null);
  const handler = (boolean) => {
    if (boolean) {
      scrollContainerRef.current.scrollLeft -= 300;
    } else {
      scrollContainerRef.current.scrollLeft += 300;
    }
    setScrollPosition(scrollContainerRef.current.scrollLeft);
  };

  useEffect(() => {
    document.getElementById('days-selector-div').scrollLeft = scrollPosition;
  }, []);

  const dayHandler = (item) => {
    setDayPicked(item);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection:'row',
      alignItems: 'center',
      gap: '20px'
    }}>
      {showArrows && (

        <IconButton onClick={() => handler(true)} sx={{
          width: '50px',
          height: '50px'
        }}>
          <IoIosArrowBack />
        </IconButton>
      )}
      <div
        onScroll={(e) => setScrollPosition(e.target.scrollLeft)}
        ref={scrollContainerRef}
        id="days-selector-div"
        key="days-selector-div"
        className={styles['days-selector']}
        style={{
          position: 'relative',
          overflowX: 'auto',
          scrollBehavior: 'auto'
        }}
      >
        {days?.map((item, index) => (
          <DayButton index={index} dayHandler={dayHandler} item={item}  dayPicked={dayPicked} palette={palette} {...props} />
        ))}
      </div>
      {showArrows && (
        <IconButton 
          onClick={() => handler(false)} 
          sx={{
            width: '50px',
            height: '50px'
          }}>
          <IoIosArrowForward />
        </IconButton>
      )}
    </div>

  );
};

const DayButton = memo(({ index, dayHandler, item, dayPicked, palette, error}) => (
  <IconButton
    key={index}
    onClick={() => dayHandler(item)}
    sx={{
      ...styledDates,
      backgroundColor:
                dayPicked === item ? palette?.primary : '#D9D9D9',
      color: dayPicked === item ? 'white' : 'black',
      border: handleDayError(error, item)
        ? '3px solid red'
        : null,
    }}
  >
    <Typography fontSize={'12px'} sx={{ textTransform: 'none' }}>
      {dayjs(item).format('MMMM')}
    </Typography>
    <Typography fontSize={'18px'} sx={{ textTransform: 'none' }}>
      {dayjs(item).format('D')}
    </Typography>
  </IconButton>
));

DayButton.displayName = 'Button';

export default memo(DaysSelector);