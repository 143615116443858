import { Box, IconButton, Typography } from '@mui/material';
import { BsFiletypeCsv } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { SiMicrosoftexcel } from 'react-icons/si';
export const CsvPreview = ({ onClose, name }) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderRadius: '12px',
      backgroundColor: '#f4f4f4',
      border: '1px solid #AEADAD',
      justifyContent: 'flex-start',
      padding: '20px',
      gap: '30px',
      position: 'relative'
    }}>
      <SiMicrosoftexcel color="#07923E" size="30px" />
      <Typography sx={{fontSize: '0.9em', fontWeight: '600'}}>name: {name}</Typography>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '5%',
          right: '2%'
        }}>
        <AiOutlineCloseCircle />
      </IconButton>
    </Box>
  );
};