import { Box, Typography } from '@mui/material';
import { FiUpload } from 'react-icons/fi';
import { CsvPreview } from './CsvPreview';
import { useState } from 'react';


export const UploadCsv = ({setFile}) => {
  const [fileUpload, setFileUpload] = useState(null);
  const handleClose = () => {
    setFileUpload(null);
    setFile(null);
  };
  const handleClick = (e) => {
    const file = e.target.files[0];
    setFileUpload(file);
    setFile && setFile(file);
  };    
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    }}>
      <Box sx={{
        cursor: 'pointer',
        backgroundColor: '#F4F4F4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '200px',
        borderRadius: '20px',
        border: '1px solid #AEADAD',
        position: 'relative',
        userSelect: 'none'
      }}>
        <input
        //   ref={fileInputRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: 'inherit',
            height: 'inherit',
            right: 0,
            opacity: 0.0,
            cursor: 'pointer'
          }}
          // hidden
          accept=".csv"
          type="file"
          onChange={handleClick}
        />
        <FiUpload size="70px" />
        <Typography sx={{
          fontSize: '.9em',
          fontWeight: '600',
        }}>Upload as csv</Typography>
      </Box>
      {!!fileUpload && <CsvPreview onClose={handleClose} name={fileUpload?.name} />}
    </Box>

  );
};