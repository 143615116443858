

export const formFilters = {
  talents: [
    {
      key: 'category',
      value: 'category'
    },
    {
      key: 'status',
      value: 'Last Submission Status'
    },
    {
      key: 'completed',
      value: 'Completed'
    },
    {
      key: 'draft',
      value: 'Visibility'
    },
    {
      key: 'tags',
      value: 'Tags'
    }
  ],
  events: [
    {
      key: 'date',
      value: 'dates'
    },
    {
      key: 'category',
      value: 'category'
    },
    {
      key: 'status',
      value: 'Last Submission Status'
    },
    {
      key: 'completed',
      value: 'Completed'
    },
    {
      key: 'tags',
      value: 'Tags'
    },
    {
      key: 'scheduled',
      value: 'Scheduled'
    }
  ],
  locations: [
    {
      key: 'category',
      value: 'category'
    },
    {
      key: 'geo_tagged',
      value: 'Geo tagged'
    },
    {
      key: 'tags',
      value: 'Tags'
    }
  ],
  articles: [
    {
      key: 'category',
      value: 'category'
    }
  ],
  experiences: [
    {
      key: 'category',
      value: 'category'
    }
  ],
  faqs: [
    {
      key: 'category',
      value: 'category'
    }
  ],
  notifications: [
    {
      key: 'status_notifications',
      value: 'Status'
    }
  ],
  access: [
    {
      key: 'source',
      value: 'Source',
    },
    {
      key: 'date',
      value: 'dates',
      festivalDates : false
    }
  ]

};