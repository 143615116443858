import {  Box, Button, Typography } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import {  useResourceContext } from 'react-admin';
import { MdFileUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { ImportComponent, WizardModal } from './WizardModal';
import { DownloadTemplate } from './DownloadTemplate';
import { MessageInput } from '../InputsForm/MessageAutocomplete';
import { UploadCsv } from './UploadCsv';
import { IoMdDownload } from 'react-icons/io';
import { LuClipboardEdit } from 'react-icons/lu';
import { useImportPost } from './functions';
import DoneIcon from '@mui/icons-material/Done';


const styleButton ={
  width: '130px',
  fontWeight: '600',
  display: 'flex',
  height: '44px',
  alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  textTransform: 'none',
  gap: '10px',
  color: '#8A8A8A',
  backgroundColor: '#fff',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#fff'
  }
};
export const ImportActions = ({setOpen: onClose}) => {
  const resource = useResourceContext();
  const [open, setOpen] = useState(false);
  const palette = useSelector((state) => state?.palette?.palette);
  const [file, setFile] = useState(null);
  const { refetch, loading, error, success} = useImportPost();
  // const options = {
  //   onError: () => setOpen(false),
  //   onSuccess: () => setOpen(false)
  // };
  const handleImport = useCallback(() => {
    return refetch(resource, file);
  },[file]);

  return (
    <Fragment key="import-button">
      <Button variant="contained" sx={styleButton} onClick={() => setOpen(true)} startIcon={<MdFileUpload />}>Upload</Button>
      { open && (
        <WizardModal
        onClose={() => {onClose(false); setOpen(false)}}
          steps={import_steps({ file, setFile, resource, palette })}
          success={success} 
          loading={loading} 
          onSaveDisable={!file} 
          onSave={handleImport}
          palette={palette}
          error={error}
          open={open} 
          setOpen={setOpen}
          resource={resource}
        />
      )}
    </Fragment>
  );
};


export const import_steps = ({file, setFile, resource, palette}) => {


  return [
    {
      key: 0,
      title: 'Download template',
      icon: IoMdDownload,
      description: `An XLS template will be downloaded with the fields you selected for your ${resource} the file will contain info over each field, any new column will be ignored.`,
      component: <DownloadTemplate resource={resource} />
    },
    {
      key: 1,
      title: 'Fill Template',
      icon: LuClipboardEdit,
      description: <span>
                Fill the template with all the necessary information.
        <br />
        <br />
                    DO NOT
        <br />
        <br />
        • Add columns
        <br />
        • Change the disposition of the sheets 
      </span>,
      component: <MessageInput text="Please respect the rules for data import. Any doubt or additional requests you can contact us to rocio@muvinai.com" />
    },
    {
      key: 2,
      title: 'Upload template',
      icon: MdFileUpload,
      description: 'Once your template is filled you need to upload it',
      component: <UploadCsv setFile={setFile} />,
      disabledNext: !file,
      onSaveAction: 'Import',
      onSave: true,
      errorProps: {
        title: "Results",
        description: null,
        component: <MessageInput type="error" text="There was an error when uploading your data. The following file contains all the rows that were not uploaded with a detail. Reupload the file with the corrections to import the data." />
      },
      successProps: {
        title: "Results",
        component: <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap:"20px"
        }}>
          <Box sx={{
            width: "140px",
            height: "140px",
            borderRadius: "50%",
            backgroundColor: palette?.success,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>

          <DoneIcon sx={{
            width: "110px",
            height: "110px",
            color: "#fff"
          }} />
          </Box>
          <Typography sx={{
            fontSize: "1.1em",
            fontWeight: "600",
            textAlign:"center"
          }}>
          Your data was uploaded successfuly! <br /> You can now view it in your {resource} table. 
          </Typography>
        </Box>
      }
    },
  ];
};
