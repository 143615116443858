import { Box, Button, Modal } from '@mui/material';
import { useMemo, useState } from 'react';
import { Wizard } from '../Wizard/Wizard';
import { ImportTemplate } from './ImportTemplate';


export const WizardModal = (props) => {
  const { onClose, error, success, loading, steps, palette,onSaveDisable, setOpen, open, onSave} = props;
  const [step, setStep] = useState(0);
  const { primary = 'black' } = palette;


  const lastStep = useMemo(() => {
    if(!steps[step].onSave) return false;
    return steps[step];
  }, [step]);

    
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        backgroundColor: '#fff',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection:'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: '20px',
        borderRadius: '12px',
        minHeight: '600px',
        minWidth: '800px',
        padding: '10px'
      }}>
        <Box sx={{
          width: '100%'
        }}>
          <Wizard {...props} step={step} setStep={setStep} />
          <ImportTemplate {...steps[step]} {...props} />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          alignSelf: 'flex-end',
          justifySelf: 'flex-end',
          width: '100%',
          gap: '20px',
          //   marginRight: '20px',
          padding: '20px'
        }}>
          {(error || success) && <Button 
          sx={{ 
            color: primary,
            backgroundColor: '#fff',
            border: `1px solid ${primary}`
          }} 
          variant="outlined" 
          onClick={onClose}
          >
            exit
            </Button>}
         { ![success,error].some((item) => !!item) && step !== 0 && (
           <Button sx={{ 
            color: primary,
            backgroundColor: '#fff',
            border: `1px solid ${primary}`
          }} 
          variant="outlined" 
          onClick={() => setStep(prev => prev - 1)}
          >back</Button>
         )}
         { ![success,error].some((item) => !!item) &&  <Button
            sx={{
              width: '150px',
              color: '#fff', 
              backgroundColor: primary, 
              border: steps[step].disabledNext ? 'none' : `1px solid ${primary}`
            }}
            disabled={lastStep && (onSaveDisable || loading)}
            variant="contained" 
            onClick={lastStep ? onSave : () => setStep(prev => prev + 1)}>
            {lastStep ? lastStep.onSaveAction : 'Next'}
          </Button>
}
        </Box>
      </Box>
    </Modal>
  );
};
