import {
  Chart as ChartJS,
  LinearScale,
  Title,
  CategoryScale,
  BarElement,
  elements,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box } from '@mui/material';
import { getRandomHexColor } from '../functions/functions';
import { useSelector } from 'react-redux';
import { complementaryColor } from '../../../helpers/complementaryColor';
// import 'chartjs-plugin-datalabels';

  
const ChartBarComponent = ({xAxis, maxHeight = '400px', data, labels}) => {

  const palette = useSelector((state) => state.palette.palette);
  console.log(palette);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    elements,
    Tooltip,
    Legend,
    // ChartjsPluginStacked100,
    ChartDataLabels,
  );

  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: xAxis ? 'x' : 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'right',
        labels: {
          value: {
            color: 'black'
          }
        },
        color: 'black',
        offset: 0,
        clip: false,
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: false
        }

      },
      
    },
    scales: {
      x: {
        beginAtZero: true,
        barPercentage: 1.0,      
        categoryPercentage: 1.0, 
        stacked: false,
        reverse: false,
        afterUpdate: (e) => {
          if(!xAxis) {
            let scaleTick = e?.ticks?.[1];
            e.max = e?.max + (scaleTick?.value / 2);
          }
        },
       
      },
      y: {

        beginAtZero: true,
        barPercentage: 1.0,      
        categoryPercentage: 1.0,
        grid: {
          display: false
        },
        ticks: {
          callback: function(value) {
            if(!xAxis) {
              let label = labels?.[value];
              const maxLength = 10; // Set max characters
              return label?.length > maxLength ? label.substr(0, maxLength) + '…' : label;
            }
          }
        }
      },
    },
  };

  const dataset = {
    labels: labels,
    datasets: [
      {
        label: 'Event',
        data: data,
        barThickness: 50,
        backgroundColor: data?.map((item, index) => {
          index /= data.length + 5;
          console.log(complementaryColor(palette.primary, index));
          return complementaryColor(palette.primary, index);
        }),

      },
    ]
  };
  
  return (
    <Bar style={{
      maxHeight: maxHeight,
    }} options={options} data={dataset} />
    
  );
};
  
export default ChartBarComponent;