import { CircularProgress } from '@mui/material';
import React from 'react';
import { useCreate } from 'react-admin';
import { IoMdSave } from 'react-icons/io';
import { Button } from '../Generics/index';

const ActionButtons = ({
  noIcon,
  loading,
  label,
  onClick,
  icon,
  mutationOptions,
  alwaysEnable,
  palette,
  sx,
  ...rest
}) => {
  const [_, { isLoading }] = useCreate();

  return (
    <Button
      style={{
        padding: '8px',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: loading ? '#d2d2d2' : palette?.primary,
        fontWeight: 500,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        lineHeight: '1.75',
        ...sx,
      }}
      hoverStyle={{ color: palette?.primary, backgroundColor: '#ffffff' }}
      label={label}
      onClick={onClick}
      disabled={loading || isLoading}
      {...rest}
    >
      {noIcon ? null : loading || isLoading ? (
        <CircularProgress size={'20px'} sx={{ color: '#fff' }} />
      ) : (
        <IoMdSave size={'20px'} style={{ marginRight: '8px' }} />
      )}
      {label ? label : rest.isEdit ? 'Save' : 'Create'}
    </Button>
  );
};

export default ActionButtons;
