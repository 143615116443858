import { CircularProgress, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import { IoCloseCircleOutline } from 'react-icons/io5';


export const WizardButton = (props) => {
  const { error: errorRequest, success, item, setStep, selected, setted, length, index, loading } = props;
  const error = selected && errorRequest
  const { primary, success: successColor, error: errorColor } = useSelector((state) => state?.palette?.palette);
  console.log(error)
  const isLast = length - 1 !== index;
  
  return (
    <div className={`wizard-button-container ${isLast ? 'last' : ''}`}>
      <IconButton
        onClick={() => setStep(index)}
        sx={{
          '--primary-color': primary,
          '--success-color': successColor,
          '--error-color': errorColor,
        }}
        className={`icon-button ${error && !isLast ? "icon-button-error" : ""} ${selected ? 'icon-button-selected' : ''} ${setted ? 'icon-button-setted' : ''}`}
      >
        {(setted || !!success) ? (
          <DoneIcon size="25px" />
        ) : error && !isLast ? (
            <IoCloseCircleOutline size="25px" />
        ) : selected ? (
            <item.icon size="25px" />
        ) : (
          <item.icon size="25px" />
        )}
      </IconButton>
      {isLast && (
        <div 
          className="progress-bar-container"
          style={{
            '--primary-color': primary,
            '--success-color': successColor,
            '--error-color': errorColor,

          }}
        >
          <div 
            className={`progress-bar ${setted ? 'setted' : ''}`}
            style={{
              '--primary-color': primary,
              '--success-color': successColor,
              '--error-color': errorColor,

            }}
          />
        </div>
      )}
      <div className="last-child-container">
        <div className={!isLast ? 'last-child-last' : 'last-child'}>
          <span className="step-typography">step {index + 1}</span>
          <span className="typography">{item.title}</span>
        </div>
      </div>
    </div>
  );
};