
import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { useInput } from 'react-admin';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { createObj, formatObj, InputAddTextInputs } from '../../../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { IoAddCircleOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { useFormContext } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';


const OptionsInput = (props) => {
  const {
    source,
    index
  } = props;

  const { field } = useInput({ 
    source: source,

  });
  const { getValues } = useFormContext();
  const [inputOptions, setInputOptions] = useState(field?.value[index]?.options ?? []);
  useEffect(() => setInputOptions(field.value[index]?.options??[]),[index, getValues('id')]);


  const handleChange = (e, index) => {
    let arr = inputOptions?.map((item, i) => {
      if(i === index) {
        return e.target.value;
      }
      return item;
    });
    setInputOptions(arr);
  };

  useEffect(() => {
    const arr = field.value?.map((item, i)=> {
      if(i === index) {
        return {
          ...item, 
          options: inputOptions
        };
      }
      return item;
    });
    field.onChange(arr);
  },[inputOptions]);
  


  const addValue = () => {
    return setInputOptions((f) => ([...f, '']));
  };


  const handleDelete = (i, intIndex) => {
    const arr = field.value?.map((item, index) => {
      if(index === intIndex){
        return {
          ...item,
          options: item?.options.filter((int, ind) => i !== ind)
        };
      } else {
        return item;
      }
    });
    setInputOptions(arr[index]?.options);
    return field.onChange(arr);
  };

  const MemoChip = useMemo(() => {
    return inputOptions?.map((item, i) => (
      <ChipInput 
        interestIndex={index}
        index={i}
        value={item}
        onDelete={handleDelete}
        onChange={handleChange}
      />
    )
    );
  },[inputOptions]);

  return (
    <Box sx={{
      gap: '10px'
    }}>
      <InputTitle>Options</InputTitle>
      <InputSubtitle>You can add up to 10 interests. With a max of 30 characthers each.</InputSubtitle>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: 'auto',
        gap: '10px'
      }}>

        {MemoChip}
        
        <IconButton 
          onClick={addValue}
        >
          <IoAddCircleOutline  />
        </IconButton>
      </Box>
    </Box>
  );
};
  
export const ChipInput = (props) => {
  const { 
    value,
    onDelete,
    onChange,
    index,
    interestIndex
  } = props;
  const [inputValue, setInputValue] = useState(value ?? '');
  useEffect(() => setInputValue(value), [value]);
  const [inputWidth, setInputWidth] = useState('auto');
  const spanRef = useRef(null);

    
  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(`${spanRef.current.offsetWidth}px`);
    }
  }, [inputValue]);

  const handleChange = (e, index) => {
    setInputValue(e.target.value);
    onChange(e, index);
  };

  return (
    <div style={{ 
      position: 'relative',
      display: 'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent: 'space-between',
      height: '40px',
      outline: 'none',
      border: '2px solid #434343',
      padding: '2px 2px 2px 20px', // Add padding to the right for the icon
      width: 'auto',
      backgroundColor: '#fff',
      color: '#000', // Changed to black for better readability
      borderRadius: '22px',
    }}>
      <input
        ref={spanRef}

        type="tel"
        value={inputValue}
        onChange={(e) => handleChange(e, index)}
        style={{
          border: 'none',
          outline: 'none',
          fontSize: '1em',
          fontWeight: '400',
          width: inputWidth,
          padding: '2px 5px 2px 2px',
          fontFamiliy: 'Open-sans',
          textTransform: 'capitalize',
          resize: 'hotizontal',
          boxSizing: 'content-box', // Ensures padding is included in the width calculation
        }}
      />
      <span
        ref={spanRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          height: '0',
          padding: '2px 5px 2px 2px',
          whiteSpace: 'pre',
        }}
      >
        {inputValue}
      </span>
      <AiOutlineCloseCircle
        size="20px"
        onClick={() => onDelete(index, interestIndex)}
        color="grey"
        style={{
          cursor: 'pointer',
          marginRight: '5px'
        }}
      />
    </div>
  );
};

export default memo(OptionsInput);