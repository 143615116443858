import { useRef, useMemo, useState } from 'react';
import { useGetList } from 'react-admin';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
//Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { apiUrl } from '../../../../dataProvider';
import { useOnClickOutside } from '../../../../Hooks/useOnClickOutside';
//Components
import {Button} from '../../../../Components/Generics/';
import { MessageInput } from '../../../../Components/InputsForm/MessageAutocomplete';
import { Spinner } from '../../../../Components/Spinner';
import { InputAddCategories } from '../FormInfoSections/categoriesInformation';
//Material & Icons
import { Box, CircularProgress, Divider, Grid, Modal, Typography } from '@mui/material';
import { IoClose } from 'react-icons/io5';

function checkEqual(values, previousValues) { 
  if(values?.length !== previousValues?.length) return true;
  for(let i = 0; i < values?.length; i++){
    let found = previousValues?.find(item => item.id === values[i].id);
    if(!found) return true;
    if(found) {
      if(values[i].text.name !== found.text.name) {
        return true;
      }
      continue;
    }
    continue;
  }
  return false;
}

function transform(data, record) {
  let arr = [];
  for(const [index, category] of data.entries()) {
   
    if(!!record[index] && category.id !== record[index]?.id){
      arr.push({
        prev: record[index].text.name,
        new: null
      });
      continue;
    }
    if(record[index] === undefined) {
      arr.push({
        prev: null,
        new: category.text.name
      });
      continue;
    }
    if(category.text.name === record[index].text.name) {
      continue;
    }
    
    arr.push({
      prev: record[index].text.name,
      new: category.text.name
    });
  }

  if(record?.length > data?.length) {
    for (const [index, prev] of record.entries()){
      if(data[index] === undefined){
        arr.push({
          prev: prev.text.name,
          new: null
        });
      }
    }
  }
  return arr;
};

export const EditCategoryModal = (props) => {
  
  const palette = useSelector(state => state?.palette?.palette);
  const { open, setOpen, resource} =  props; 
  const [selectedCategories, setSelectedCategories] = useState(null);
  const { refetch, data: record, isFetching } = useGetList(`schemas/${resource}/categories`, undefined, {
    onSuccess: ({data}) => {
      setSelectedCategories(data);
    }, 
    
    enabled: !!open
  });

  const customToast = useCustomToasty();
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(null));

  const { refetch: onSubmit , isFetching: isFetchingPut} = useQuery(['put_category'], async () => {
    const sendData = transform(selectedCategories, record);
    return await axios({
      url: `${apiUrl}/schemas/${resource}/categories`,
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: sendData
    });
  },{
    onSuccess: (res) => {
      customToast('success', `Categories for ${resource} updated.`);
      // setOpen(false);
      refetch();
    },

    onError: (error) => {
      if(error.response?.status === 400) return customToast('error', error.response.data.detail);
      customToast('error', 'There was an error, please try again later.');
    },
    enabled: false
  });

  const disabledSave = useMemo(() => {
    return checkEqual(selectedCategories, record);
  },[isFetching, selectedCategories]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
    >

      <Box
        ref={ref}
        padding={5}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '20px',
        }}
      >
        <IoClose size="25px" style={{position: 'absolute', top: 0, right: 0, margin: 30, cursor: 'pointer'}} onClick={() => setOpen(false)} />

        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginBottom: 2,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.5em',
              fontWeight: '700',
              fontFamily: 'Open-Sans'
            }}
          >
          Edit Categories
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            fontWeight: '400',
            fontFamiliy: 'Open-Sans'
          }}>
        Create, modify or delete existing categories.
          </Typography>
        </Box>
        <MessageInput text="You will be unable to delete a category that is used in one or more elements"/>
        {
          !isFetching && !!selectedCategories ? (
            <Grid container mt={1}>
              <Grid item xs={7} >
                <Typography sx={{marginX: 2, color: '#7C7C7C', fontSize: '.8em', fontWeight: '600', fontFamily: 'Open-Sans'}}>
                Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography sx={{  marginX: 2, color: '#7C7C7C', fontSize: '.8em', fontWeight: '600', fontFamily: 'Open-Sans'}}>
                Appearences
                </Typography>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              <Grid item xs={12} style={{
                maxHeight: '60vh',
                overflowY: 'scroll'
              }}>
                <InputAddCategories 
                  fullwidth
                  onChange={setSelectedCategories}
                  value={selectedCategories}
                  source="categories" 
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  disabled={isFetchingPut || !disabledSave} 
                  onClick={onSubmit} 
                  style={{backgroundColor:palette?.primary, color:'#ffffff',fontWeight: 600}}
                  hoverStyle={{ backgroundColor: palette?.secondary}}
                >
                  {isFetchingPut && <CircularProgress size="15px" style={{marginRight: '8px'}}/>}
                  Save
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box 
              sx={{
                position: 'relative',
                height: '300px',
                width: '100%',
              }}>
              <Spinner 
                sx={{
                  transform: 'translate(-50%, -50%)'
                }} />
            </Box>
          )
        }
      </Box>
    </Modal>
  );
};