import { useSelector } from 'react-redux';
import { dynamicColor } from '../../../../helpers/dynamicColor';
import { useMemo } from 'react';

export const LocationPin = ({ 
  size = 30, 
  src, 
  titleForPin, 
  original,
  disabled,
  selected
}) => {
    
  const palette = useSelector((state) => state?.palette?.palette);
  const backgroundColor = dynamicColor(palette?.primary, 0.2)?.rgba;
  
  const pinStyle = useMemo(() => {
    return {
      cursor: 'pointer',
      fill: disabled ? '#000000': selected ? palette.primary : '#348feb',
      stroke: '#fff',
      strokeWidth: '2',
    };
  },[selected]);

  return (
    <div
      id={'pinValue'}
      style={{
        padding: 1,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      { typeof titleForPin === 'string' && titleForPin.length > 0 && (
        <LocationTitle  original={original} backgroundColor={backgroundColor} title={titleForPin} />
      )}
      {src ? (
        <img src={src} style={{ width: size, height: size }} id={'pinValue'}/>
      ) : (
        <svg id={'pinValue'} height={size} viewBox="0 0 24 24" style={pinStyle}>
          <circle id={'pinValue'} cx="12" cy="12" r="10" />
        </svg>
      )}
    </div>
     
  );
};

export const LocationTitle = ({ original, backgroundColor, title }) => {
  return (
    <div
      id={'pinValue'}
      style={{
        backgroundColor: original && 'white',
        borderRadius: '10px',
      }}
    >
      <span
        id={'pinValue'}
        style={{
          color: original ? 'black' : 'white',
          fontSize: '14px',
          backgroundColor: original ? backgroundColor : '#000000AD',
          padding: 5,
          borderRadius: '10px',
        }}
      >
        {title}
      </span>
    </div>
  );
};