export function complementaryColor(hexColor, lightnessAdjustment) {
  // Remove the "#" if present
  hexColor = hexColor.replace('#', '');
    
  // Check for 6 or 8 characters (8 includes alpha)
  const hasAlpha = hexColor.length === 8;

  // Extract RGB values (and alpha if present)
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  const a = hasAlpha ? parseInt(hexColor.substring(6, 8), 16) : 255; // Default alpha is 255 (fully opaque)

  // Convert RGB to HSL
  const { h, s, l } = rgbToHsl(r, g, b);

  // Adjust the lightness
  let newLightness = l + lightnessAdjustment;
  newLightness = Math.max(0, Math.min(1, newLightness)); // Clamp between 0 and 1

  // Convert adjusted HSL back to RGB
  const { r: newR, g: newG, b: newB } = hslToRgb(h, s, newLightness);

  // Convert RGB back to hex, including alpha if originally present
  const adjustedHex = `#${toHex(newR)}${toHex(newG)}${toHex(newB)}${hasAlpha ? toHex(a) : ''}`;
  return adjustedHex;
}


// Converts an integer to a 2-digit hex string
function toHex(n) {
  return n.toString(16).padStart(2, '0');
}

function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
    case g: h = (b - r) / d + 2; break;
    case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return { h, s, l };
}

// Converts HSL to RGB
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

