import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useUtcFormat } from '../../../helpers/date';
import { required, useDataProvider, useInput } from 'react-admin';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { InputTitle } from '../../StyledComponents/InputTitle';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import localeEnGB from 'dayjs/locale/en-gb'; // No borrar esto que permite el formateo de los inputs a DD/MM/YYYY
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const handleFormat = (e) => { return dayjs(e).format('YYYY-MM-DD - HH:MM');};
const handleValidate =(e, duration, openTimes, _format, stage) => {
  if(!stage) return undefined;
  let parsedValue = _format(e);
  let foundBefore = openTimes?.find((item, index) => {

    let open = dayjs(new Date(_format(item.open)).setUTCSeconds(0)).toDate();
    let close = dayjs(new Date(_format(item.close)).setUTCSeconds(0)).toDate();

    if(dayjs(dayjs(parsedValue)).isBetween(open, close, undefined, '[)')) return item;
  });
  if(!foundBefore) {
    return 'The selected date starts at a time where the selected stage is closed.';
  }
  let foundAfter = openTimes.find((item, index) => {

    let addedDuration = dayjs(dayjs(parsedValue)).add(duration, 'minutes');
    let open = dayjs(new Date(_format(item.open)).setUTCSeconds(0)).toDate();
    let close = dayjs(new Date(_format(item.close)).setUTCSeconds(0)).toDate();

    if(dayjs(dayjs(addedDuration)).isBetween(open, close, undefined, '(]')) return item;
  });

  if(!foundAfter) {
    return 'The selected start date ends at a closed time for the selected stage.';
  }
  return undefined;
};

export const InputDateTime = ({ source, title='Date Time', subtitle, shouldDisableTime }) => {
    
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  const { getValues } = useFormContext();
  
  const days = useSelector((state) => state.data.info.days);
  const timeZone = useSelector((state) => state?.data?.info?.timezone?.offset);
  const { location_id, duration } = getValues();

  const dataProvider = useDataProvider();
  const [openTimes, setOpenTimes] = useState(null);

  const { refetch } = useQuery(['open_times'], () => {
    return dataProvider.getOne('locations', {id: location_id});
  },{
    onSuccess: ({data}) => {
      setOpenTimes(data?.open_times);
    },
    enabled: false
  });

  useEffect(() => {
    refetch();
    return() => {
      setOpenTimes(null);
    };
  },[location_id]);
  
  const { field } = useInput({ 
    source, 
    validate: [
      // required(`The ${source} is required.`),
      (e) => handleValidate(e, duration, openTimes, formatFestivalTime, true )
    ],
    format: (e) => formatFestivalTime(e),
    parse: (e) => parseFestivalTime(e), 
    defaultValue: dayjs(days[0])
    
  });

  const handleChange = (e) => {
    const date = e.format('YYYY-MM-DD');
    const time = e.format('THH:mm:ss');
    const dateTime = date + time;
    field.onChange(dateTime);
  };

  const disableDays = useCallback((date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    return !days.includes(formattedDate);
  }, [days]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <InputTitle>{title}</InputTitle>
      <InputSubtitle>{subtitle}</InputSubtitle>
      <Box sx={{display: 'flex', flexDireciton: 'row', gap: 3}}>
        <div>
          <InputTitle>Start time</InputTitle>
          <DateTimePicker 
            variant="outlined"
            value={dayjs(field.value)}
            onChange={handleChange}
            orientation='landscape'
            slotProps={{
              textField: {
                variant: 'outlined'
              }
            }}
            shouldDisableDate={disableDays}
            // shouldDisableTime={disableTime}
            minDate={dayjs(days[0])}
            maxDate={dayjs(days[days.length-1])}
            shouldDisableTime={shouldDisableTime}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }} 
          />
        </div>
        <div>
          <InputTitle>End time</InputTitle>
          <DateTimePicker 
            variant="outlined"
            value={dayjs(field.value).add(duration, 'minutes')}
            disabled
            minDate={dayjs(days[0])}
            maxDate={dayjs(days[1])}
            slotProps={{
              textField: {
                variant: 'outlined'
              }
            
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }} 
          />
        </div>
      </Box>
    </LocalizationProvider>
  );

};