import { Box, Typography } from '@mui/material';

export const DashboardCard = ({ sx, title, children }) => (
  <Box sx={{
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    width: '100%',
    padding: '10px',
    height: 'auto',
    boxShadow: '1px 0px 5px 1px rgba(0, 0, 0, 0.2)',
    ...sx
  }}>
    { title && <Typography sx={{fontSize: '1em', fontWeight: '600'}}>{title}</Typography>}
    {children}
  </Box>
);