import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useGetList, useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';

// Hooks & Utils
import { LOCATIONS_TOOLTIPS } from '../../../TalentForm/Tooltips';
// Components
import InputText from '../../../../Components/InputsForm/InputText';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { InputImage } from '../../../../Components/InputsForm/InputImage';
// Material & Icons
import { Typography, Switch } from '@mui/material';
import { MapComponent } from './MapComponent';

const VenueMap = () => {
  const [allCoordinates, setAllCoordinates] = useState(undefined);
  const CENTER_COORD = useSelector((state) => {
    return state?.data?.info?.location?.geometry?.coordinates;
  });
  const { getValues } = useFormContext();
  const record = useRecordContext();
  const { refetch } = useGetList(
    'locations',
    {
      filter: {
        geo_tagged: true,
      },
    },
    {
      onSuccess: ({ data }) => {
        const onlyCoordinates = data?.flatMap((location) =>
          location?.location?.map((feature) => ({
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
            name: location.name,
            icon: location.icon,
            id: location.id,
          })),
        );
        setAllCoordinates(onlyCoordinates);
      },
    },
  );
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    const form = getValues();
    let location = form.location;
    if (location?.length >= 1) {
      setShowMap(true);
    }
  }, []);
  return (
    <>
      {!CENTER_COORD ? (
        <Typography>
          You can not put this location on the map as the festival owner has not
          established the location of the festival, please contact them
        </Typography>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          }}
        >
          <InputSubtitle>
            Mark this if your location will be visible in the interactive app
            map
          </InputSubtitle>
          <Switch
            sx={{ ...switchStyleIos }}
            checked={showMap}
            onClick={() => setShowMap(!showMap)}
          />
        </div>
      )}
      {showMap && CENTER_COORD && (
        <>
          <InputImage
            title={'Icon'}
            tooltip={LOCATIONS_TOOLTIPS['icon']}
            source="icon"
            action="url"
          />
          <InputText
            source={'brief_description'}
            fullWidth
            counter={150}
            title={'Brief description'}
            subtitle={'This is the description that will appear in the map pin'}
          />
          <MapComponent
            CENTER_COORD={CENTER_COORD}
            allCoordinates={allCoordinates}
          />
        </>
      )}
    </>
  );
};


export default VenueMap;
