import { strCapitalize } from './strCapitilize';


export const datelabelFormatter = (str, key = 'month', type = 'short') => {
  return strCapitalize(
    str.toLocaleDateString('en-EN', {
      [key]: type,
    })
  );
};
