import { Box, Typography } from '@mui/material';
import { DashboardCard } from './DashboardCard';
import ChartBarComponent from './ChartBarComponent';

export const TicketsMetrics = ({access}) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '30px'
    }}mt={4}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap:'30px',
        width: '100%'
      }} >
        <DashboardCard title="Tickets Scanned Today">
          <Typography sx={{fontSize: '2.3em', fontWeight: '600'}}>{access?.total_today}</Typography>
        </DashboardCard>
        <DashboardCard title="Total Tickets Scanned">
          <Typography sx={{fontSize: '2.3em', fontWeight: '600'}}>{access?.total_festival}</Typography>
        </DashboardCard>
        <DashboardCard title="Scans/Total Tickets">
          <Typography sx={{fontSize: '2.3em', fontWeight: '600'}}>{access?.ticket_scanned_percentage}%</Typography>
        </DashboardCard>
      </Box>
      <DashboardCard sx={{width: 'auto'}} title="Most Popular Posts">
        <ChartBarComponent xAxis labels={access?.total_per_day.map(item => item.date)} data={access?.total_per_day.map(item => item.count)} />
      </DashboardCard>
    </Box>
  );
};