export const statusValues = ['Approved', 'Pending', 'Rejected', 'Not Submitted'];
export const accessStatusValues = ['manual', 'scan'];
export const statusNotifications = ['Sent', 'Canceled', 'Scheduled'];
export const completeValues = [{
  title: 'Complete', 
  name: 'true'
}, {
  title: 'Incomplete',
  name: 'false'
}];
export const visiblityValues = [{
  title: 'Visible',
  name: 'false'
},{
  title: 'Hidden',
  name: 'true'
}
];
export const scheduleValues = [
  {
    title: 'Not Scheduled',
    name: 'false'
  },{
    title: 'Scheduled',
    name: 'true'
  }
];

export const getTaggedValues = [{
  title: 'Yes',
  name: 'true'
},{
  title: 'No',
  name: 'false'
}
];