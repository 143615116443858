
export const ROLES = [
  {
    id: 0,
    text: 'superadmin'
  }, 
  {
    id: 1,
    text: 'owner'
  }, 
  {
    id: 2,
    text: 'admin'
  },
  {
    id: 3,
    text: 'staff'
  }, 
  {
    id: 4,
    text: 'talent_manager'
  }
];

//resources = ['talent_managers', 'events', 'venues', 'admins', 'heros', 'notifications', 'faqs']

export const PERMISSIONS = {
  homeRedesign: {
    create: ['superadmin', 'admin', 'staff'], //role
    update: ['superadmin', 'admin', 'staff' ],
    delete: ['superadmin', 'admin','staff'],
    show: ['superadmin', 'admin'],
    read: ['superadmin', 'admin']
  },
  festivals: {
    create: ['superadmin'], //role
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  talentsForm: { //resource
    create: [ 'talent_manager'], //role
    update: [ 'talent_manager'],
    delete: [ 'talent_manager'],
    read: [ 'talent_manager']
  },
  talents: { //resource
    create: ['superadmin', 'admin', 'staff'], //role
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin','staff'],
    show: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff', 'talent_manager']
  },
  submissions: { //resource
    create: ['superadmin', 'admin', 'staff'], //role
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    show: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  dashboard: { //resource
    create: ['superadmin', 'admin', 'staff'], //role
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    show: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  dashboardTM: { //resource
    create: ['talent_manager'], //role
    update: ['talent_manager'],
    delete: ['talent_manager'],
    show: ['talent_manager'],
    read: ['talent_manager']
  },
  events: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin', 'admin','staff', 'talent_manager']
  },
  schedule: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin', 'admin','staff', 'talent_manager']
  },
  locations: {
    create: ['superadmin', 'staff'],
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  experiences: {
    create: ['superadmin', 'staff'],
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  editions: {
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  admins: {
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  heros: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  // ****  APP CUSTOMIZATION SECTION ****
  homeComponents:{
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  more:{
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  interests:{
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  notifications: {
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  popups: {
    create: ['superadmin', 'staff'],
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  categories: {
    create: ['superadmin', 'admin', 'staff'],
    update: ['superadmin', 'admin', 'staff'],
    delete: ['superadmin', 'admin','staff'],
    read: ['superadmin', 'admin','staff']
  },
  articles: {
    create: ['superadmin', 'staff'], //role
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    show: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  access: {
    create: ['superadmin', 'staff'],
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  // ****  UTILITIES SECTION ****
  faqs: {
    create: ['superadmin', 'staff'],
    update: ['superadmin', 'staff'],
    delete: ['superadmin','staff'],
    read: ['superadmin','staff']
  },
  resources: {
    create: ['superadmin', 'staff'], //role
    update: ['superadmin', 'staff' ],
    delete: ['superadmin','staff'],
    show: ['superadmin','staff', 'talent_manager'],
    read: ['superadmin','staff', 'talent_manager']
  },
  // ****  SETTINGS SECTION ****
  users: {
    create: ['superadmin'], //role
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  versions: {
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  },
  settings: {
    create: ['superadmin'],
    update: ['superadmin'],
    delete: ['superadmin'],
    read: ['superadmin']
  }
};