import {
  Box,
  Button,
  Chip,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AddFilter, CustomTextField } from './FilterFields';
import { decDateString } from '../../helpers/dateFormatter';
import { datelabelFormatter } from '../../helpers/dateLabelFormatter';
import { useSelector } from 'react-redux';
import { strCapitalize } from '../../helpers/strCapitilize';
import { getTaggedValues, scheduleValues, visiblityValues } from './FilterFields/staticFilters';
import { formFilters } from './resourceFilters';
  
export default function FilterToolbar(props) {
  const { tab, filters, setFilters, resource  } = props;
  const palette = useSelector((state) => state.palette.palette);

  
  const parsedFilters = useMemo(() => {
    let obj = { ...filters };
    if (Object.keys(obj).includes('start_date')) {
      const [year, month, day] = decDateString(obj['start_date']);
      const [secondYear, secondMonth, secondDay] = decDateString(obj['end_date']);
  
      let fromDate = new Date(Number(year), Number(month) - 1, Number(day));
      let untilDate = new Date(
        Number(secondYear),
        Number(secondMonth) - 1,
        Number(secondDay)
      );
  
      let monthName = datelabelFormatter(fromDate);
      let secondMonthName = datelabelFormatter(untilDate);
  
      obj.date = `${fromDate.getFullYear()} | ${
        monthName !== secondMonthName
          ? `${monthName} → ${secondMonthName}`
          : monthName
      } | ${fromDate.getDate()} → ${untilDate.getDate()}`;
      delete obj.start_date;
      delete obj.end_date;
    }
    if(Object.keys(obj).includes('geo_tagged')){
      obj['geo_tagged'] = getTaggedValues.find(item => item.name === obj.geo_tagged).title;
    }
    if(Object.keys(obj).includes('scheduled')){
      obj['scheduled'] = scheduleValues.find(item => item.name === obj.scheduled).title;
      
    }
    return obj;
  }, [filters]);

  const removeFilter = useCallback(
    (e) => {
      let obj = { ...parsedFilters };
      if (e.date) {
        delete e.from;
        delete e.until;
      }
      delete obj[e];
      return setFilters(obj);
    },
    [parsedFilters]
  );
  
  const formattedLabel = useCallback(
    (e) => {
      let parseFilter = parsedFilters?.[e];
      const value = formFilters[resource].find(item => item.key === e)?.value;
      
      if (Array.isArray(parseFilter)) {
        const replace = parseFilter.length > 3 ? ' ... ' : ' - ';
        const formattedFilter = parseFilter.length > 3 
          ? [parseFilter[0], parseFilter[parseFilter.length - 1]] 
          : parseFilter;
        const str = decodeURIComponent(formattedFilter.join(replace)) || '';
  
        return e === 'dates' ? `date | ${str}` : ` ${value} | ${str}`;
      }
      
      let str = decodeURIComponent(parseFilter);
      if (value === 'Visibility') {
        str = parseFilter === 'true' ? 'false' : 'true';
      }
      
      return ` ${value} | ${str || ''}`;
    },
    [parsedFilters]
  );
  
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5px',
        padding: '10px 20px'
      }}
    >
      {Object.keys(parsedFilters)?.map((item, index) => {
        if (parsedFilters?.[item] && item !== 'q') {
          return (
            <Box key={`${item}-${index}`}>
              <Chip
                sx={{
                  backgroundColor: '#fff',
                  border: `1px solid ${palette.primary}`,
                  color: palette.primary,
                  textTransform: 'capitalize'
                }}
                
                key={`${item}-${index}`}
                label={formattedLabel(item)}
                onDelete={
                  tab !== 'boletas' && item === 'status'
                    ? null
                    : () => removeFilter(item)
                }
              />
            </Box>
          );
        }
      })}
    </Box>
  );
}
  