export const dateFormatter = (year, month, day) => {
  let setMonth = parseInt(month);
  let setDay = parseInt(day);
  
  if (month <= 9) {
    setMonth = `0${month}`;
  }
  if (day <= 9) {
    setDay = `0${day}`;
  }
  return `${year}-${setMonth}-${setDay}`;
};
  
export const decDateString = (str) => {
  return str.split('-');
};
  