import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { ConfirmButtons } from './DatePickerComponents/ConfirmButtons';
import { RadioButton } from './DatePickerComponents/RadioButton';

export const CustomDateField = (props) => {
  const [selector, setSelector] = useState('day');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px 0',
        backgroundColor: '#fff'
      }}
      key="date-calendar"
    >
      <ConfirmButtons {...props} type={selector} />
    </Box>
  );
};
