import { Box, Button, Checkbox, FormControl, FormControlLabel, Modal, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useListContext, useResourceContext } from 'react-admin';
import { MdFileUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { strCapitalize } from '../../helpers/strCapitilize';
import { baseInputsNotRequired, filteredKeys } from '../../Resources/Info/Form/FormInfoSections/schemaExceptions';

export const DownloadTemplate = ({ resource }) => {
  const { primary, secondary } = useSelector((state) => state?.palette?.palette);
  const [useData, setUseData] = useState(false);

  const { data } = useListContext();
  const cols = useSelector((state) => {
    return [
      ...state.data.schemas[resource].base.filter(item => !filteredKeys.includes(item)).filter(item => !baseInputsNotRequired.includes(item)), 
      ...state.data.schemas[resource].required];
  });

  const handler = () => {
  
    const csvRows = [
      cols, 
      useData ? data.map(item => cols?.map(it => item[it])) : []
    ].map(row => row.join(',')).join('\n');
  
    const blob = new Blob([csvRows], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
  
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
  
    }}>
      <FormControlLabel slotProps={{ 
        typography: {
          sx: {
            fontSize: '0.8em',
            fontWeight: '600'
          }
        }
      }} label="Download with existing data" control={
        <Checkbox  sx={{
          color: primary,
          '&.Mui-checked': {
            color: primary,
          },
        }} onClick={() => setUseData(prev => !prev)} checked={useData} />
      } />
      <Button variant="contained" sx={{backgroundColor: primary, color: '#fff'}} onClick={handler}>Download Template</Button>
    </Box>
  );
};