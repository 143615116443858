import React, { useRef, useState } from 'react';
// Hooks & Utils
import { useListContext, useRedirect, useResourceContext } from 'react-admin';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
import { useSelector } from 'react-redux';
// Components
import { AddTalentModal } from '../../Resources/Talents/Form/TalentsListComponents/AddTalentModal';
import { CsvDownloader } from '../ReportButtons/Csv/CsvDownloader';
import FilterList from '../Filters/FilterList';
import ActionButtons from '../StyledComponents/ActionButtons';
// Material & Icons
import { Box, Divider, Popover } from '@mui/material';
import { NewFilter, SearchComponent } from '../Filters/FilterComponents';
import OrderModal from '../OrderModal/OrderModal';

import { HiDotsVertical } from 'react-icons/hi';
import { Button } from '../Generics';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { ImportActions } from '../ImportComponents/ImportActions';
import FilterToolbar from '../FilterListComponent/FilterToolbar';
import { AddFilter } from '../FilterListComponent/FilterFields';

const titleResource = {
  fontSize: '36px',
  fontWeight: 700,
  lineHeigh: '50px',
  margin: 0,
  padding: 0,
  color: '#000000',
  textTransform: 'capitalize',
  marginTop: '10px',
};

export const ListActions = ({
  permission,
  placeholder,
  search,
  csv,
  submissionList,
  label,
  hasCreate,
  upload,
  types,
}) => {
  const { filterValues, setFilters } = useListContext();

  const redirect = useRedirect();
  const resource = useResourceContext();
  const palette = useSelector((state) => state?.palette?.palette);

  const pickActionText = () => {
    switch (resource) {
    case 'locations':
      return 'name';
    case 'faqs':
      return 'question';
    default:
      return 'name';
    }
  };

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        {permission !== 'talent_manager' && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingX: '20px',
                marginBottom: 2,
              }}
            >
              <p style={titleResource}>{resource}</p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 20,
                  justifyContent: 'space-between',
                }}
              >
                {search ? (
                  <SearchComponent
                    sx={{ width: '350px', borderRadius: '10px' }}
                    placeholder={placeholder}
                  />
                ) : <Box />}
                <div
                  style={{
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    gap: 20,
                    marginLeft: 50,
                    // position: 'relative'
                  }}
                >
                  {resource === 'talents' ? (
                    <AddTalentModal />
                  ) : (
                    hasCreate && (
                      <ActionButtons
                        sx={{
                          width: '100px',
                          textTransform: 'capitalize',
                          fontSize: '16px',
                        }}
                        noIcon
                        onClick={() => redirect('create', resource)}
                        label={'Create'}
                        palette={palette}
                      />
                    )
                  )}
                  {resource === 'locations' || resource === 'faqs' ? (
                    <OrderModal actionText={pickActionText()} />
                  ) : null}
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <AddFilter resource={resource} filters={filterValues} setFilters={setFilters} />
                  </Box>
                  <OptionDots
                    from={resource}
                    csv={csv}
                    upload={upload}
                    filterValues={filterValues}
                  />
                </div>
                
              </div>
            </Box>
          </>
        )}
        <FilterToolbar resource={resource} filters={filterValues} setFilters={setFilters} />

      </Box>
    </>
  );
};
const btDotStyle = {
  backgroundColor: '#E8E8E8',
  height: '45px',
};

export const OptionDots = ({ from, csv, filterValues, upload }) => {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div ref={buttonRef}>
        <Button
          style={{ ...btDotStyle, width: '45px' }}
          onClick={() => setOpen(true)}
        >
          <HiDotsVertical size={18} color="grey" />
        </Button>
      </div>
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            marginTop: '10px',
            padding: '2px 0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {csv && <CsvDownloader from={from} filterValues={filterValues} />}
        <Divider />
        {
          upload && <ImportActions setOpen={setOpen} />
        }
      </Popover>
    </>
  );
};
export const FilterWrapper = ({ setShowFilters, showFilters }) => {
  return (
    <>
      <div>
        <Button
          style={{ ...btDotStyle, padding: '0px 30px' }}
          onClick={() => setShowFilters(!showFilters)}
        >
          <p
            style={{
              color: '#7C7C7C',
              textTransform: 'none',
              fontSize: '16px',
              lineHeight: '22px',
              marginRight: '5px',
              fontWeight: 600,
            }}
          >
            Filters
          </p>
          {showFilters ? (
            <MdExpandLess size={20} color="grey" />
          ) : (
            <MdExpandMore size={20} color="grey" />
          )}
        </Button>
      </div>
    </>
  );
};
