export function getRandomHexColor() {
  const hex = Math.floor(Math.random() * 16777215).toString(16);
  return `#${hex.padStart(6, '0')}`;
}

export function applyOthers (arr, type = 'number') {
  let maxNumber= 7;
  let length = arr.length > maxNumber ? maxNumber : arr.length;
  if(type === 'number'){
    return Array.from({ length: length} ,(_, index) => {
      if(arr.length > maxNumber && index === length - 1) return arr.reduce((a, b, i) => i >= maxNumber ? a + b : 0, 0);
      return arr[index];
    });
  }
  if(type === 'string') {
    return Array.from({ length: length} ,(_, index) => {
      if(arr.length > maxNumber && index === length - 1) return 'Others';
      return arr[index];
    });
  }
}