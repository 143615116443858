import { Box } from "@mui/material";

export const GridDates = ({ sx, children }) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      //   gap: "15px",
      width: "100%",
      gap: "15px",
      padding: "5px",
      ...sx,
    }}
  >
    {children}
  </Box>
);
