import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import './wizard.css';
import { WizardButton } from './WizardButton';

export const Wizard = (props) => {
  const {
    steps,
    setStep,
    step,
    loading
  } = props;
  const [selectedSteps, setSelectedSteps] = useState(step);

  useEffect(() => {
    setSelectedSteps(step);
    return () => {
      setSelectedSteps(step);
    };
  },[step]);

  const handleSelect = (int) => {
    setStep(int);
    setSelectedSteps((prev) => prev < int ? int : prev);
  };
  return (
    <Box className="wizard-container">
      {
        steps?.map((item, index) => (
          <WizardButton
          {...props}
            loading={loading}
            setted={selectedSteps > index}
            setStep={handleSelect}
            selected={step === index}
            length={steps.length}
            item={item}
            index={index}
          />
        ))
      }
    </Box>
  );
};


