import { Box, IconButton, MenuItem, TextField } from '@mui/material';
import { useGetList } from 'react-admin';
import { ChoicesButtons } from '../ChoicesButtons';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { CustomDateField } from '../DatePicker';
import { accessStatusValues, completeValues, getTaggedValues, scheduleValues, statusNotifications, statusValues, visiblityValues } from '../staticFilters';

export const CustomTextField = ({ removeFilter, children, ...rest }) => (
  <TextField
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: '200px',
    }}
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      sx: {
        textAlign: 'center',
        verticalAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        alignSelf: 'center',
        height: '50px',
        ...rest.styles,
      },
    }}
    {...rest}
  >
    {children}
  </TextField>
);
  
export const CustomSelectField = ({
  source,
  removeFilter,
  choices,
  ...rest
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      //   width: "100%",
    }}
  >
    <CustomTextField
      {...rest}
      styles={{
        minWidth: '200px',
        height: '40px',
      }}
      select
    >
      {choices.map((item, index) => (
        <MenuItem sx={{ width: '100%' }} value={item.value} key={item.key}>
          {item.title}
        </MenuItem>
      ))}
    </CustomTextField>
    {removeFilter && <RemoveFilter />}
  </Box>
);
  
export const RemoveFilter = ({ source, removeFilter }) => (
  <IconButton onClick={() => removeFilter(source)}>
    <DoNotDisturbAltIcon
      style={{ color: '#a0a0a0', height: '20px', width: '20px' }}
    />
  </IconButton>
);
  
export const ChoicesDropdown = (props) => {
  const { source,resource } = props;
  console.log(source);
  const { data } = useGetList(`${resource}/categories`, undefined, { enabled: source === 'category'});
  const { data: tags } = useGetList(`${resource}/tags`, undefined,  { enabled: source === 'tags'});
 
  switch(true){
  case source === 'status_notifications':
    return (
      <ChoicesButtons choices={statusNotifications?.map(item => ({'name': item}))} isMulti {...props} />
    );
  case source === 'geo_tagged':
    return (
      <ChoicesButtons choices={getTaggedValues} optionLabel="title" {...props} />
    );
  case source === 'scheduled':
    return (
      <ChoicesButtons choices={scheduleValues} optionLabel="title" {...props} />
    );
  case source === 'tags':
    return (
      <ChoicesButtons capitalize choices={tags?.map(item => ({'name': item.text}))}  isMulti {...props} />
    );
  case source === 'draft':
    return (
      <ChoicesButtons choices={visiblityValues} optionLabel="title" {...props} />
    );
  case source === 'completed':
      
    return (
      <ChoicesButtons optionLabel="title" choices={completeValues} {...props} />
    );
  case source === 'source':
      
    return (
      <ChoicesButtons choices={accessStatusValues?.map(item => ({'name': item}))} isMulti {...props} />
    );
  case source === 'status':
    return (
      <ChoicesButtons choices={statusValues?.map(item => ({'name': item}))} isMulti {...props} />
    );
  case source === 'category':
      
    return (
      <ChoicesButtons capitalize choices={data?.map(item => ({'name': item.text}))} isMulti {...props} />
    );
  case source === 'date':
    return (
      <CustomDateField {...props}  />
    );
  default: return <></>;
  }
};