import React, { useEffect, useState } from 'react';
import {
  FunctionField,
  TextField,
  useListContext,
  useRefresh,
} from 'react-admin';
import dayjs from 'dayjs';
//Hooks & Utils
import { useUtcFormat } from '../../helpers/date';
//Components
import { Indicator } from '../../Components/RedesignComponents/Indicator';
import { ListActions } from '../../Components/ListActions/ListActions';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { RedirectModal } from '../Talents/TalentsList';
//Material & Icons
import { Typography, Chip } from '@mui/material';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { COLORS } from '../../assets/constants/theme';

const EventsList = () => {
  const { setFilters, displayedFilters, filterValues } = useListContext();
  const [redirectModal, setRedirectModal] = useState({ bool: false, id: null });

  const handlePopup = (status, id) => {
    if (status === 'pending') return setRedirectModal({ bool: true, id: id });
    return 'edit';
  };
  const [filter, setFilter] = useState({});
  const refresh = useRefresh();

  React.useEffect(() => {
    refresh();
  }, [filter]);

  useEffect(() => {
    if (window.location.href.includes('pending')) {
      let baseUrl = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, baseUrl);
    }
  }, []);

  useEffect(() => {
    if (!displayedFilters?.date && displayedFilters?.length > 0) {
      let newValues = { ...filterValues };
      delete newValues.start_date_from;
      delete newValues.end_date_to;
      return setFilters({ ...newValues });
    }
  }, [displayedFilters]);
  const { formatFestivalTime } = useUtcFormat();

  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: (
        <ListActions
          from={'events'}
          upload
          placeholder={'Search by name, description, stage and talents...'}
          search={true}
          csv={true}
        />
      ),
      queryOptions: {
        meta: { field: 'last_modified' },
      },
    },
    datagridParams: {
      bulkActionButtons: false,
      sx: {
        padding: '0px 20px',
        '& .column-picture.original': {
          width: '15%',
        },
        '& .MuiTypography-root': {
          color: '#000000',
          fontWeight: 400,
        },
        '& .column-name': {
          width: '15%',
        },
        '& .column-category': {
          width: '10%',
          textAlign: 'center',
        },
        '& .column-talentId': {
          width: '15%',
        },
        '& .column-completed': {
          width: '5%',
          textAlign: 'center',
        },
        '& .column-status': {
          width: '15%',
          textAlign: 'center',
        },
        '& .column-start': {
          width: '12.5%',
        },
        '& .column-end': {
          width: '12.5%',
        },

      },
      rowClick: (id, resource, record) =>
        handlePopup(record?.submission?.status, id),
    },
  };
  return (
    <div>
      <ListCustom
        filter={filter}
        defaultTab={
          window.location.href.includes('pending') ? 'main' : 'pending'
        }
        empty={<Typography>No submissions</Typography>}
        setFilter={setFilter}
        {...paramsList}
      >
        <TextField source="name" />
        <FunctionField
          label="Category"
          source="category"
          render={(record) => {
            if (record?.category === null) return null;
            return <Chip sx={{ width: 'auto',backgroundColor:'#D9D9D9' }} label={record?.category} />;
          }}
        />
        <FunctionField
          label="Talent"
          source="talentId"
          render={(record) => {
            if (record?.talents[0] === null) return null;
            return <TextField label="Talent" source="talents[0].name" />;
          }}
        />
        <FunctionField
          label="Complete?"
          source="completed"
          render={(record) => (
            <Typography
              sx={{
                fontSize: '.9em',
                fontWeight: '600',
                color: record?.completed ? '#009b00' : '#ffc990',
                textTransform: 'uppercase',
              }}
            >
              {record?.completed ? (
                <MdCheckCircle color="#21B141" size={26} />
              ) : (
                <IoMdCloseCircle color="grey" size={26} />
              )}
            </Typography>
          )}
        />
        <FunctionField
          label="Last submission status"
          source="status"
          render={(record) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {record?.submission?.status === 'draft' ||
              record?.submission === null ||
              record?.submission.status === 'not_submitted' ? (
                  <Indicator data={'not_submitted'} />
                ) : (
                  <Indicator data={record?.submission?.status} />
                )}
            </div>
          )}
        />
        <TextField source="location.name" label="Location" />
        <FunctionField
          label="Start date"
          source="start"
          render={(record) => {
            if (record?.start_date)
              return dayjs(formatFestivalTime(record.start_date)).format(
                'DD/MM/YYYY, HH:mm:ss',
              );
            return 'Not scheduled';
          }}
        />
        <FunctionField
          label="End date"
          source="end"
          render={(record) => {
            if (record?.start_date)
              return dayjs(formatFestivalTime(record.end_date)).format(
                'DD/MM/YYYY, HH:mm:ss',
              );
            return '';
          }}
        />
      </ListCustom>
      <RedirectModal
        resource="events"
        open={redirectModal.bool}
        id={redirectModal.id}
        setOpen={setRedirectModal}
      />
    </div>
  );
};

export default EventsList;
