import { createSlice } from '@reduxjs/toolkit';
import { createFontColor } from '../../helpers/createFontColor';

export const paletteSlice = createSlice({
  name: 'palette',
  initialState: {
    palette: {
      primary: '#8502C2',
      secondary: '#4F1769',
      tertiary: '#D359C7',
      background:'#EDEDED',
      input_background:'D9D9D9',
      warning:'#FFC700',
      success:'#21B141',
      error:'B12121',
      gray:'#D9D9D9',
      info:'#447BBC',
    }
  },
  reducers: {
    setPalette: (state, action) => {
      state.palette = {...action.payload, fontColor: createFontColor(state?.palette?.primary)};
    }
  }
});
export const { 
  setPalette, 
} = paletteSlice.actions;
  
export default paletteSlice.reducer;