import { useState } from 'react';
import { GridDates } from './GridDates';
import { RadioButton } from './RadioButton';
import { parseValuesToFilter } from './functions';

export const MonthSelector = (props) => {
  const months = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
  };
  const { filters, handleAccept } = props;
  const todayMonth = new Date().getMonth() + 1;
  const [selected, setSelected] = useState(months[todayMonth]);

  const handleSelect = (e, index) => {
    setSelected(e);
    let year = new Date().getFullYear();
    let month = index + 1;
    let firstDay = new Date(year, month, 1).getDate();
    let lastDay = new Date(year, month, 0).getDate();
    handleAccept(parseValuesToFilter(year, month, [firstDay, lastDay]));
  };

  return (
    <GridDates>
      {Object.values(months).map((item, index) => (
        <RadioButton
          key={`${index} - month`}
          type={item}
          onClick={(e) => handleSelect(e, index)}
          boolean={selected === item}
        />
      ))}
    </GridDates>
  );
};
