import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { GeolocateControl, Layer, Map, Marker, NavigationControl, Source, useControl } from 'react-map-gl';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { Button, TextField } from '@mui/material';
import { LocationPin } from './LocationPin';
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';

//Con el nuevo geojson
export const MapComponent = ({ CENTER_COORD, allCoordinates }) => {
  //Hooks
  const mapRef = useRef(null);
  const { getValues, setValue } = useFormContext();
  const [selectedElement, setSelectedElement] = useState(null);
  const [isBorderOn, setIsBorderOn] = useState(false);
  const customToast = useCustomToasty();
  const form = getValues();
  const title = form.name;
  const icon = form.icon;
  const initialLng =
      form?.location?.length >= 1
        ? form.location[0]?.type === 'Point'
          ? form.location[0]?.geometry?.coordinates?.[0]
          : CENTER_COORD[0]
        : CENTER_COORD[0];
  const initialLat =
      form?.location?.length >= 1
        ? form.location[0]?.type === 'Point'
          ? form.location[0]?.geometry?.coordinates?.[1]
          : CENTER_COORD[1]
        : CENTER_COORD[1];

  const { field } = useInput({
    source: 'location',
    defaultValue: [],
    format: (e) => {
      return e?.map((item, index) => {
        if(!item.id) {
          return {...item, id: index};
        } else {
          return item;
        }
      }).filter(item => {
        if(!item.geometry) return;
        return item;
      });
    }
  });

  const allCoordinatesNoOriginal = allCoordinates?.filter(
    (location) => location?.id !== form?.id,
  );


  //Functions
  function addMarker(event) {
    const newFeatures = event.features;
    if (newFeatures && newFeatures.length > 0) {
      let updatedFeatures = [...field.value];
      updatedFeatures.push(newFeatures[0]);
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    }
  }
  
  function DrawControl(props) {
    useControl(
      ({ map }) => {
        const draw = new MapboxDraw(props);
  
        map.on('draw.create', (e) => {
          if (props.onCreate) {
            props.onCreate(e);
          }
        });
  
        map.on('draw.update', (e) => {
          if (props.onUpdate) {
            props.onUpdate(e);
          }
        });
        return draw;
      },
      ({ map }) => {
        map.off('draw.create', props.onCreate);
        map.off('draw.update', props.onUpdate);
      },
      {
        position: props.position,
      },
    );
  
    return null;
  }
  // Handlers
  const handlerClickPolygon = (index, feature) => {
    const map = mapRef.current.getMap();
    const clickedPolygon = field.value.find((location, i) => {
      return location.geometry.type === 'Polygon' && i === index;
    });
  
    if (clickedPolygon && map) {
      const layerId = `polygon-layer-${index}`;
      map.setPaintProperty(layerId, 'fill-outline-color', '#f00');
      setSelectedElement({ id: clickedPolygon.id, type: 'polygon', element: clickedPolygon });
      setIsBorderOn(true);
      field?.value?.forEach((location, index) => {
        if (
          location?.geometry?.coordinates ===
            clickedPolygon?.geometry?.coordinates
        ) {
          return;
        }
        if (location?.type === null) {
          return;
        }
        if (location?.geometry?.type === 'Polygon') {
          const layerId = `polygon-layer-${index}`;
          map.setPaintProperty(layerId, 'fill-outline-color', 'transparent');
        }
      });
    } else {
      console.log('No polygon found for index:', index);
    }
  };
  const handleCreate = (event) => {
    addMarker(event);
  };
  const handleUpdate = (e) => {
    console.log(e, 'update e');
  };
  const handlerClickMap = (e) => {
    let target = e.originalEvent?.target?.id;
    if(target === 'pinValue') return;
    setSelectedElement(null);
    if(isBorderOn) {
      field?.value?.forEach((location, index) => {
        if (location?.type === null) {
          return;
        }
        if (location?.geometry?.type === 'Polygon') {
          const layerId = `polygon-layer-${index}`;
          mapRef.current.getMap().setPaintProperty(
            layerId,
            'fill-outline-color',
            'transparent',
          );
        }
      });
    }
  };

  
  
  const onShowLatLng = (e, v, i) => {
    let value = e.target._lngLat;
    setSelectedElement({ id: v, type: 'point', element: value });
  };
  const onDeleteElement = (e) => {
    if (selectedElement.type === 'polygon') {
      const updatedFeatures = field.value.filter((feature) => {
        return (
          feature.geometry.coordinates !==
            selectedElement.element.geometry.coordinates
        );
      });
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    } else {
      let value = selectedElement.element.lat;
      let value2 = selectedElement.element.lng;
      const updatedFields = field.value.filter((feature) => {
        return (
          feature.geometry.coordinates[0] !== value2 &&
            feature.geometry.coordinates[1] !== value
        );
      });
      field.onChange(updatedFields);
      setValue('location', updatedFields);
    }
  };
  const onMarkerDragStart = useCallback(() => {}, []);
  const onMarkerDrag = useCallback(() => {}, []);
  const onMarkerDragEnd = useCallback(
    (event, coordinates) => {
      const newLngLat = event.lngLat;
      const updatedFeatures = field.value.map((feature) => {
       
        if (feature.geometry.coordinates === coordinates) {
          return {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [newLngLat.lng, newLngLat.lat],
            },
          };
        }
        return feature;
      });
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    },
    [field, setValue],
  );

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    field?.value?.forEach((location, index) => {
      if (location?.type === null) {
        return;
      }
      if (location?.geometry?.type === 'Polygon') {
        const layerId = `polygon-layer-${index}`;
  
        map.on('mouseenter', layerId, () => {
          map.getCanvas().style.cursor = 'pointer';
        });
  
        map.on('mouseleave', layerId, () => {
          map.getCanvas().style.cursor = '';
        });
  
        map.on('click', layerId, (e) => {
          e?.originalEvent?.stopPropagation();
          handlerClickPolygon(index, e.features[0]);
        });
      }
    });
  
    return () => {
      if (map) {
        field?.value?.forEach((location, index) => {
          if (location?.type === null) {
            return;
          }
          if (location?.geometry?.type === 'Polygon') {
            const layerId = `polygon-layer-${index}`;
            map.off('mouseenter', layerId);
            map.off('mouseleave', layerId);
            map.off('click', layerId);
          }
        });
      }
    };
  }, [mapRef.current, field.value]);

  const [lat, setLat] = useState(selectedElement?.element.lat || '');
  const [lng, setLng] = useState(selectedElement?.element.lng || '');

  useEffect(() => {
    setLat(selectedElement?.element.lat || '');
    setLng(selectedElement?.element.lng || '');
  }, [selectedElement]);

  const handleLatLngChange = (e, key) => {
    let id = selectedElement?.id;
    let value = e;

    if(!value || value.length === 0 || isNaN(value)){
      return customToast('error', 'Invalid Coordinate');
    }
    if(key === 'lng' && Math.abs(value) > 180) {
      setLng(selectedElement?.element.lat || '');
      return customToast('error', 'Longitude must be between -180 and 180');

    }
    if(key === 'lat' && Math.abs(value) > 90) {
      setLat(selectedElement?.element.lat || '');
      return customToast('error', 'Longitude must be between -90 and 90');
  
    }
    setSelectedElement((state) => ({
      ...state,
      element: {
        ...state.element,
        [key]: value
      }
    }));
    let newArr = field?.value?.map(item => {

      if(item.id === id) {
        let newLatLng = item.geometry.coordinates;
        if(key === 'lng'){
          newLatLng[0] = value;
        } else {
          newLatLng[1] = value;
        }
        return {
          ...item,
          geometry: {
            ...item.geometry,
            coordinates: newLatLng
          }
        };
      }
      return item;
    });
    field.onChange(newArr);
  };
  
  return (
    <>
      <InputSubtitle>
          Select from the map where your location will be, by marking the pin you
          will be able to see how it looks against other locations
      </InputSubtitle>
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapLib={import('mapbox-gl')}
        initialViewState={{
          longitude: initialLng,
          latitude: initialLat,
          zoom: 15,
        }}
        style={{ height: '60vh', width: '100%', marginTop: '10px' }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        attributionControl={false}
        onClick={handlerClickMap}
      >
        {selectedElement && (
          <div
            style={{
              position: 'absolute',
              bottom: '5px',
              left: '10px',
              zIndex: 100,
              backgroundColor: '#000000',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'row',
              padding: '0 0px 0px 10px',
              gap: '10px'
            }}
          >
            <div
              style={{
                color: '#fff',
                fontWeight: 600,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TextField 
                inputProps={{
                  sx: { 
                    maxWidth: '150px',
                    fontWeight: '600',
                    fontSize: '.9em',
                    width: 'auto',
                    color: '#fff'
                  }}} 
                value={lng} 
                onChange={(e) => setLng(e.target.value)}
                onBlur={(e) => handleLatLngChange(Number(e.target.value), 'lng')}  

                variant="standard"
              />
              <TextField 
                inputProps={{
                  sx: { 
                    maxWidth: '150px',
                    fontWeight: '600',
                    fontSize: '.9em',
                    width: 'auto',
                    color: '#fff'
                  }}} 
                value={lat} 
                onChange={(e) => setLat(e.target.value)}
                onBlur={(e) => handleLatLngChange(Number(e.target.value), 'lat')}  
                variant="standard"
              />

            </div>
        
            <Button
              sx={{
                fontWeight: 700,
                color: '#C94A4A',
                backgroundColor: '#fff',
                height: 'auto'
              }}
              onClick={onDeleteElement}
            >
                Delete
            </Button>
          </div>
        )}
        <NavigationControl />
        <GeolocateControl />
        <DrawControl
          displayControlsDefault={false}
          controls={{
            polygon: true,
            point: true,
          }}
          position="top-right"
          onCreate={handleCreate}
          onUpdate={handleUpdate}
        />
        {field?.value?.map((location, index) => {
          
          if (
            location.type === null &&
              location?.geometry?.coordinates?.length < 2
          ) {
            return;
          }
          if (
            location.type === null &&
              location?.geometry?.coordinates?.length === 2
          ) {
            <Marker
              key={`original-noType-${index}`}
              id={location?.id}
              anchor="bottom"
              longitude={location?.geometry.coordinates[0]}
              latitude={location?.geometry.coordinates[1]}
              draggable
              onClick={(e) => onShowLatLng(e, location?.id)}
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={(event) =>
                onMarkerDragEnd(event, location.geometry.coordinates)
              }
            >
              {icon?.url ? (
                <LocationPin
                  selected={location?.id !== null && (location?.id === selectedElement?.id )? true : false}

                  pin={true} src={icon?.url} titleForPin={title} />
              ) : (
                <LocationPin 
                  selected={location?.id !== null && (location?.id === selectedElement?.id )? true : false}

                  titleForPin={title} src={icon?.url} />
              )}
            </Marker>;
          }
          if (location?.geometry?.type === 'Polygon') {
            return (
              <Source
                key={`polygon-source-${index}`}
                id={`polygon-source-${index}`}
                type="geojson"
                data={location}
              >
                <Layer
                  id={`polygon-layer-${index}`}
                  type="fill"
                  paint={{
                    'fill-color': '#892fdb',
                    'fill-opacity': 0.5,
                  }}
                />
              </Source>
            );
          } else if (location.geometry?.coordinates?.length === 2) {
            return (
              <Marker
                key={`original-${index}`}

                anchor="bottom"
                longitude={location?.geometry.coordinates[0]}
                latitude={location?.geometry.coordinates[1]}
                draggable
                onClick={(e) => onShowLatLng(e, location?.id)}
                onDragStart={onMarkerDragStart}
                onDrag={onMarkerDrag}
                onDragEnd={(event) =>
                  onMarkerDragEnd(event, location.geometry.coordinates)
                }
              >
                {icon?.url ? (
                  <LocationPin
                    pin={true} 
                    src={icon?.url} 
                    titleForPin={title}
                    selected={location?.id !== null && (location?.id === selectedElement?.id )? true : false}

                  />
                ) : (
                  <LocationPin titleForPin={title} src={icon?.url}  
                    selected={location?.id !== null && (location?.id === selectedElement?.id )? true : false}
                  />
                )}
              </Marker>
            );
          }
        })}
        {allCoordinatesNoOriginal?.map((location, index) => {
          if (location.type === 'Polygon') {
            return (
              <Source
                key={`rest-polygon-source-${index}`}
                id={`rest-polygon-source-${index}`}
                type="geojson"
                data={location}
              >
                <Layer
                  id={`rest-polygon-layer-${index}`}
                  type="fill"
                  paint={{
                    'fill-color': 'rgba(255,255,255, 1)',
                    'fill-opacity': 0.8,
                    'fill-outline-color': '#000000',
                  }}
                />
              </Source>
            );
          } else if (location.coordinates.length === 2) {
            return (
              <Marker
                key={`rest-marker-${index}`}
                anchor="bottom"
                longitude={location?.coordinates[0]}
                latitude={location?.coordinates[1]}
              >
                {location?.icon?.url ? (
                  <LocationPin
                    pin={true}
                    src={location?.icon?.url}
                    selected={location?.id !== null && (location?.id === selectedElement?.id )? true : false}
                    titleForPin={location.name}
                  />
                ) : (
                  <LocationPin disabled titleForPin={location.name} />
                )}
              </Marker>
            );
          }
        })}
      </Map>
    </>
  );
};
  