import { useMemo, useState } from 'react';
import { ListTabs } from '../Talents/Form/TalentsListTabs/ListTabs';
import { Box } from '@mui/material';
import { AppMetrics } from './Components/AppMetrics';
import { useAppMetrics } from './functions/useRequests';
import { PopularityMetrics } from './Components/PopularityMetrics';
import { TicketsMetrics } from './Components/TicketsMetrics';


const Dashboard = () => {
  const [tab, setTab] = useState('app');

  const { data: accessData } = useAppMetrics('access');
  const { data } = useAppMetrics('users');


  const tabs = [
    {
      title: 'App Metrics',
      redirectUrl: 'app',
    },
    {
      title: 'Popularity',
      redirectUrl: 'popularity',
    },
    // {
    //   title: 'Interests',
    //   redirectUrl: 'interests',
    // },
    {
      title: 'Ticketing',
      redirectUrl: 'ticketing',
    },
  ];

  const Forms = useMemo(() => {

    switch(true) {
    case tab === 'app':
      return AppMetrics;
    case tab === 'popularity':
      return PopularityMetrics;
    // case tab === 'interests':
    //   return <div>interests</div>;
    case tab === 'ticketing':
      return TicketsMetrics;
    }

  },[data, tab]);

  return (
    <Box sx={{
      padding: '10px 20px',
     
    }}>
      <ListTabs sx={{'justifyContent': 'flex-start'}} lastTab tabSx={{width:'300px'}} defaultTab={'app'} handleForm={setTab} tabs={tabs} />
      <Forms data={data} access={accessData} />
    </Box>
  );
};


export default Dashboard;