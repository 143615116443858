import { useState } from 'react';
import { GridDates } from './GridDates';
import { Box } from '@mui/material';
import { RadioButton } from './RadioButton';
import { dateFormatter } from '../../../../helpers/dateFormatter';

export const YearSelector = (props) => {
  const { handleAccept, filters } = props;

  let year = new Date().getFullYear();
  const [selected, setSelected] = useState(year);

  const handleWholeYear = (year) => {
    setSelected(year);
    let firstDay = new Date(year, 0, 1);
    let lastDay = new Date(year, 11, 31);
    handleAccept({
      from: dateFormatter(
        year,
        firstDay.getUTCMonth() + 1,
        firstDay.getUTCDate()
      ),
      until: dateFormatter(
        year,
        lastDay.getUTCMonth() + 1,
        lastDay.getUTCDate()
      ),
    });
  };
  const Years = () => {
    return (
      <GridDates>
        {Array.from(
          { length: Math.floor(year - new Date(2015, 1, 1).getFullYear()) },
          (_, index) => {
            return (
              <RadioButton
                sx={{
                  width: 'auto',
                }}
                onClick={handleWholeYear}
                boolean={Math.ceil(year - index) === selected}
                key={`${index}-year`}
                type={Math.ceil(year - index)}
              />
            );
          }
        )}
      </GridDates>
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: 'inherit',
        gap: '10px',
      }}
    >
      <Years />
    </Box>
  );
};
