import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { RadioButton } from './RadioButton';

export const DayButton = (props) => {
  const {
    hover,
    index,
    isHovered: sel,
    isWeekend,
    isSelected,
    selectedDates,
    onHover,
    borderRadius,
    disabled,
    onClick,
    onDoubleClick,
    today
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const getElement = document.getElementById(index + 1);
    const hoverFunction = (e) => {
      return onHover(e);
    };

    getElement.addEventListener('mouseover', hoverFunction);
    return () => {
      getElement.removeEventListener('mouseover', hoverFunction);
    };
  }, [hover]);
  
  const inputSx = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent:
        borderRadius === '0px 12px 12px 0px'
          ? 'flex-end'
          : borderRadius === '12px 0px 0px 12px'
            ? 'flex-start'
            : 'center',
      fontSize: '.9em',
      fontWeight: '500',
      height: '25px',
      width:
        borderRadius === '12px'
          ? '25px'
          : borderRadius === '0px'
            ? '100%'
            : index + 1 <= 9
              ? '77.5%'
              : '85%',
      alignSelf: 'center',
      borderRadius: borderRadius,
      // borderRadius: "0px",
      backgroundColor: selectedDates || sel || isHovered ? '#d6d6d6' : 'none',
      color:
      today
        ? 'blue'
        : isHovered || selectedDates
          ? '#fff'
          : isWeekend
            ? '#a6a6a6'
            : 'black',
      ':hover': {
        backgroundColor: selectedDates || sel || isHovered ? '#d6d6d6' : 'none',
      },
    };
  }, [today, sel,borderRadius, selectedDates]);

  return (
    <Box
      id={index + 1}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:
          borderRadius === '0px 12px 12px 0px'
            ? 'flex-start'
            : borderRadius === '12px 0px 0px 12px'
              ? 'flex-end'
              : 'center',
        alignItems: 'center',
      }}
    >
      <RadioButton
        icon
        key={`${index}-days`}
        onClick={onClick}
        disabled={disabled}
        onDoubleClick={onDoubleClick}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        type={index + 1}
        // value={index + 1}
        id={index + 1}
        sx={inputSx}
        boolean={selectedDates}
      />
    </Box>
  );
};
