import { Box, Typography } from '@mui/material';
import { useAppMetrics } from '../functions/useRequests';
import { DashboardCard } from './DashboardCard';


export const AppMetrics = ({ data }) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap:'30px'
    }} mt={4}>
      <DashboardCard title="Users logged to App">
        <Typography sx={{fontSize: '2.3em', fontWeight: '600'}}>{data?.total_users}</Typography>
      </DashboardCard>
      <DashboardCard title="Logged/Devices Ratio">
        <Typography sx={{fontSize: '2.3em', fontWeight: '600'}}>{((100 * data?.total_users ) / data?.devices).toFixed(2) } %</Typography>
      </DashboardCard>
    </Box>
  );
};