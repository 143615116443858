/* eslint-disable no-labels */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import {
  excludeDay,
  getDate,
  getFestivalMonth,
  handleBorderRadius,
  multiSelectValues,
  parseValuesToFilter,
  parseValueToFilterDays,
  selectMonth,
  shotWeekdayFunc,
} from './functions';
import { DayButton } from './DayButton';
import { strCapitalize } from '../../../../helpers/strCapitilize';
import { useSelector } from 'react-redux';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const DaySelector = (props) => {
  const {
    filters,
    handleAccept,
    festivalDates = true,
    value
  } = props;
  const dayToday = new Date().getDate();
  const [selected, setSelected] = useState(dayToday);
  const [dclick, setDclick] = useState(false);
  const [multiSelect, setMultiSelect] = useState([]);
  const festivalDays = useSelector((state) => state.data.info.days);
  let today = new Date();
  const [{ year, month }, setDates] = useState(
    festivalDates ?
      {
        year: parseInt(festivalDays[0].split('-')[0]),
        month: parseInt(festivalDays[0].split('-')[1]) - 1
      } : {
        year: new Date().getFullYear(),
        month: new Date().getMonth()
      }
  );


  const onDoubleClick = (e) => {
    setDclick((e) => !e);
  };

  const handleOnClick = (e) => {
    if (dclick) {
      setDclick(false);
      handleAccept(
        parseValueToFilterDays(year, month, multiSelect, value)
      );

      return;
    } else {
      setMultiSelect([e]);
      handleAccept(
        parseValueToFilterDays(year, month, [e], value)
      );
    }
  };

  const setHover = useCallback(
    (e) => {
      let value = e.target.id;

      if (dclick) {
        if (value !== '') {
          setSelected(parseValuesToFilter(year, month, [parseInt(value)]));

          return setMultiSelect((prev) =>
            multiSelectValues(prev, parseInt(value))
          );
        }
      } else {
        if (value !== '') {
          setSelected(parseValuesToFilter(year, month, multiSelect));
        }
      }
    },
    [dclick]
  );

  const numberDays = useMemo(() => {
    const lastDayIndex = new Date(year, month + 1, 0).getDate();
    return lastDayIndex;
  }, [year, month]);

  const dateTitle = useMemo(() => {
    return `${new Date(year, month, 1).toLocaleDateString('en-EN', {
      month: 'long',
    })} ${year}`;
  },[year, month]);

  const daysGrid = useCallback(
    (numberDays) => {
      let firstDay = shotWeekdayFunc(year, month, 1);
      const festivalDaysInMonth = getFestivalMonth(festivalDays, month);
      const ElementsObj = [];
      innerLoop: for (let j = 0; j <= days.length; j++) {
        if (days[j] !== firstDay) {
          ElementsObj.push(<Box key={`${j}-days-empty`} />);
        } else {
          break innerLoop;
        }
      }
      for (let i = 0; i < numberDays; i++) {
        let isToday = Boolean((month === today.getMonth()) && (today.getDate() === i + 1));
        
        ElementsObj.push(
          <DayButton
            today={isToday}
            onHover={setHover}
            selectedDates={[
              getDate(filters)?.first,
              getDate(filters)?.last,
            ].includes(i + 1)}
            onClick={handleOnClick}
            onDoubleClick={onDoubleClick}
            disabled={festivalDates ? !excludeDay(festivalDaysInMonth, i + 1) : false}
            isSelected={selected}
            isHovered={multiSelect.includes(i + 1)}
            borderRadius={handleBorderRadius(multiSelect, i + 1)}
            index={i}
            // isWeekend={isWeekend}
            hover={dclick}
          />
        );
      }

      return ElementsObj;
    },
    [year, month, multiSelect, dclick, selected, filters]
  );

  const handleSelectMonth = useCallback((boolean) => {
    if(!festivalDates){
      if(month === 11 && boolean) {
        return setDates({year: year + 1, month: 0});
      }
      else if(month === 0 && !boolean) {
        return setDates({year: year - 1, month: 11});

      } else {

        return setDates({year: year, month: boolean ? month + 1 : month - 1});
      }
    }
    let result = selectMonth(festivalDays, month + 1, boolean);

    let [selectedMonth, selectedYear] = result.split('/');
    return setDates({year: parseInt(selectedYear), month: parseInt(selectedMonth) - 1});
  }, [year, month]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        width: '100%',
      }}
    >
      <Typography sx={{marginLeft: '10px', fontSize: '.8em', textTransform: 'uppercase', fontWeight: '1000'}}>Festival dates</Typography>
      <Box sx={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap:'10px'}}>

        <Typography
          sx={{
            fontSize: '1.2em',
            fontWeight: '600',
            marginLeft: '10px',
          }}
        >
          {strCapitalize(dateTitle)}
        </Typography>
        <Box sx={{display: 'flex',
          flexDirection:'row',
          alignItems: 'center'
        }}>

          <IconButton onClick={() => handleSelectMonth(false)}>
            <IoIosArrowBack size="15px" />
          </IconButton>
          <IconButton onClick={() => handleSelectMonth(true)}>
            <IoIosArrowForward size="15px" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          padding: '5px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {days?.map((item, index) => (
          <h7
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'auto',
              color: [days[0], days[days.length - 1]].includes(item) ? 'red' : 'black',
            }}
            key={`${index}-days-name`}
          >
            {item}
          </h7>
        ))}
        {daysGrid(numberDays).map((item) => React.cloneElement(item))}
      </Box>
    </Box>
  );
};
