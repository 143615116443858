
export const baseInputsNotRequired = [

  'start_date', 
  'duration', 
  'location_id',
  '_draft',
  'event_ready'

];

export const filteredKeys = ['_archived', '_draft', 'event_ready'];
