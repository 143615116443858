import { strCapitalize } from '../../helpers/strCapitilize';
import { COLORS } from '../../assets/constants/theme';
import { IoEllipse } from 'react-icons/io5';
import {Box,Alert,Tooltip} from '@mui/material';
import { formatOnlyDate } from '../../helpers/date';


export const Indicator = ({
  sx, 
  propColor, 
  children, 
  data = 'not_submitted'
}) => {
  
  const bgColor = ()=>{
    if(data !== undefined || data !== null){
      switch(data){
      case 'approved':
        return COLORS.table.indicators.background.approved;
      case 'rejected':
        return COLORS.table.indicators.background.rejected;
      case 'pending':
        return COLORS.table.indicators.background.pending;
      case 'not_submitted':
        return COLORS.table.indicators.background.not_submitted;
      case 'draft':
        return COLORS.table.indicators.background.draft;
      default:
      }
    }
  };
  const fontColor = ()=>{
    if(data !== undefined || data !== null){
      switch(data){
      case 'approved':
        return COLORS.table.indicators.fontColor.approved;
      case 'rejected':
        return COLORS.table.indicators.fontColor.rejected;
      case 'pending':
        return COLORS.table.indicators.fontColor.pending;
      case 'not_submitted':
        return COLORS.table.indicators.fontColor.not_submitted;
      case 'draft':
        return COLORS.table.indicators.fontColor.draft;
      default:
      }
    }
  };

  const sxApp = {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    paddingX:'8px',
    paddingY:'0px', 
    height:'auto',
    width:'auto',
    backgroundColor:bgColor(),
    borderRadius:'10px',
    '& .MuiAlert-message':{
      color:fontColor(),
      fontWeight:500,
    },
    '& .MuiAlert-icon':{
      width:'auto',
    }
  };
  return (
    <Box
      sx={{
        height:'35px',
        borderRadius: '12px',
        width: 'auto',
        maxWidth:'140px',
        ...sx,
      }}
    >
      {data !== null && data !== 'not_submitted' ?
        <Tooltip title={`Last submitted ${formatOnlyDate(data?.last_modified)}`} placement='top'>
          <Alert sx={sxApp} icon={<IoEllipse size={14} color={fontColor()}/>}>
            {strCapitalize(data)}
          </Alert>
        </Tooltip>  
      
        : <Alert sx={sxApp} icon={<IoEllipse size={14} color={fontColor()}/>}>
          Not Submitted
        </Alert>
      }
    </Box>
  );
};
  