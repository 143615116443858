import { Button, IconButton } from "@mui/material";

export const RadioButton = ({
  icon,
  // onDoubleClick,
  onClick,
  type,
  boolean,
  sx,
  ...rest
}) => {
  if (icon) {
    return (
      <IconButton
        value={type}
        // onDoubleClick={handleDClick}
        onClick={() => onClick(type)}
        sx={{ width: "100%", ...sx }}
        variant={boolean ? "contained" : "none"}
        {...rest}
      >
        {type}
      </IconButton>
    );
  }
  return (
    <Button
      onClick={() => onClick(type)}
      sx={{ width: "100%", ...sx }}
      variant={boolean ? "contained" : "outlined"}
      {...rest}
    >
      {type}
    </Button>
  );
};
