export function createFontColor(hexColor) {
  let color = getOppositeColor(hexColor);
  color = color.replace('#', '');

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);


  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    
  return brightness > 128 ? 'black' : 'white';
}

export function getOppositeColor(hex) {
  // Remove any leading '#' and convert to 6 characters
  hex = hex.replace(/^#/, '').padStart(6, '0');

  // Parse the hex color as integers
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Calculate the opposite color by inverting each component
  const oppositeR = (255 - r).toString(16).padStart(2, '0');
  const oppositeG = (255 - g).toString(16).padStart(2, '0');
  const oppositeB = (255 - b).toString(16).padStart(2, '0');

  // Combine the opposite color components
  return `#${oppositeR}${oppositeG}${oppositeB}`;
}