import dataProvider from './dataProvider';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import Events from './Resources/Events';
import Talents from './Resources/Talents';
import { QueryClient } from 'react-query';
import { lightTheme } from './Theme';
import LayoutCustom from './Components/LayoutCustom/LayoutCustom';
import LoginPageCustom from './Resources/Login/LoginPageCustom';
import authProvider from './authProvider';
import Schedule from './Resources/Schedule';
import Venues from './Resources/Venues';
import Activities from './Resources/Activities';
import Notifications from './Resources/Notifications';
import HomeComponents from './Resources/HomeComponents/index.js';
import More from './Resources/More/index.js';
import Faqs from './Resources/Faqs';
import Blogs from './Resources/Articles/Blogs';
import Admins from './Resources/Admins';
import { MdOutlineAppSettingsAlt } from 'react-icons/md';
import { FaBullhorn } from 'react-icons/fa';
import { MdOutlinePendingActions } from 'react-icons/md';
import { IoMdPerson } from 'react-icons/io';
import { userAccessControl } from './helpers/permissions';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route } from 'react-router-dom';
import { ProfileForm } from './Resources/TalentForm/ProfileForm';
import { ContactInformation } from './Resources/TalentForm/ContactInformation';
import FormEvents from './Resources/Events/Form/FormEvents.jsx';
import { HospitalityForm } from './Resources/TalentForm/HospitalityForm';
import TalentsList from './Resources/Talents/TalentsList';
import EventSubmissions from './Resources/Submissions/EventSubmissions/index.js';
import TalentSubmissions from './Resources/Submissions/TalentSubmissions/index.js';
import DashboardTM from './Resources/Dashboard/DashboardTM/index';
import Dashboard from './Resources/Dashboard/index';
import Info from './Resources/Info/index.js';
import Onboarding from './Resources/Onboarding/index.js';
import Interests from './Resources/Interests/index.js';
import Access from './Resources/Access/index.js';
dayjs.extend(utc);
dayjs.extend(timezone);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    dayjs.tz.setDefault('America/Costa_Rica'); // Modificar esto para el get de info
  }, []);

  return (
    <>
      <Admin
        queryClient={queryClient}
        dataProvider={dataProvider}
        theme={lightTheme}
        layout={LayoutCustom}
        authProvider={authProvider}
        loginPage={LoginPageCustom}
        requireAuth
        disableTelemetry
      >
        {(permissions) => [
          userAccessControl(permissions, 'read', ['talentsForm']) ? (
            <CustomRoutes>
              <Route
                path={'/talent-information'}
                // path={`/talent-information/${getToken()?.talent_id}`}
                element={<TalentsList />}
              />
              ,
              <Route
                path={'/profile'}
                // path={`/profile/${getToken()?.talent_id}`}
                element={<ProfileForm isEdit />}
              />
              ,{/*(NEW VIEW TM) ERROR DE pathname si existe el antiguo path= */}
              <Route
                path={'/info'}
                // path={`/info/${getToken()?.talent_id}`}
                element={<ContactInformation />}
              />
              ,
              <Route
                path={'/event'}
                // path={`/event/${getToken()?.talent_id}`}
                element={<FormEvents isEdit from={'talents'} />}
              />
              ,
              {/* <Route
                path={'/marketing'}
                // path={`/marketing/${getToken()?.talent_id}`}
                element={<MarketingForm />}
              /> */}
              <Route
                path={'/hospitality'}
                // path={`/hospitality/${getToken()?.talent_id}`}
                element={<HospitalityForm />}
              />
              ,
            </CustomRoutes>
          ) : null,
          userAccessControl(permissions, 'read', ['dashboard']) ? (
            <Resource
              name='dashboard'
              options={{ label: 'Dashboard' }}
              {...Dashboard}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['dashboardTM']) ? (
            <Resource
              name='dashboardtm'
              options={{ label: 'Dashboard' }}
              {...DashboardTM}
            />
          ) : null,
          //******************* CONTENT SECTION *******************
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource name='CONTENT' options={{ isTitle: true }} />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='Talent'
              options={{ isMenuParent: true }}
              icon={IoMdPerson}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['talents']) ? (
            <Resource
              name='talents'
              options={{ label: 'Active', menuParent: 'Talent' }}
              {...Talents}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='talent_submissions'
              options={{
                label: 'Submissions',
                menuParent: 'Talent',
                notifications: true,
              }}
              {...TalentSubmissions}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='Event'
              options={{ isMenuParent: true }}
              icon={MdOutlinePendingActions}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['events']) ? (
            <Resource
              name='events'
              options={{ label: 'Active', menuParent: 'Event' }}
              {...Events}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['events']) ? (
            <Resource
              name='event_submissions'
              options={{
                label: 'Submissions',
                menuParent: 'Event',
                notifications: true,
              }}
              {...EventSubmissions}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['schedule']) ? (
            <Resource
              name='schedule'
              options={{ label: 'Schedule' }}
              {...Schedule}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['locations']) ? (
            <Resource
              name='locations'
              options={{ label: 'Locations' }}
              {...Venues}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['articles']) ? (
            <Resource
              name='articles'
              options={{ label: 'Articles' }}
              {...Blogs}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['experiences']) ? (
            <Resource
              name='experiences'
              options={{ label: 'Experiences' }}
              {...Activities}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['faqs']) ? (
            <Resource name='faqs' options={{ label: 'FAQs' }} {...Faqs} />
          ) : null,
          userAccessControl(permissions, 'read', ['access']) ? (
            <Resource name='access' options={{ label: 'Access' }} {...Access} />
          ) : null,
          //******************* APP CUSTOMIZATION SECTION *******************
          userAccessControl(permissions, 'read', [
            'popups',
            'faqs',
            'homeComponents',
            'posts',
          ]) ? (
              <Resource
                name='APP CUSTOMIZATION'
                options={{ isTitle: true }}
                icon={MdOutlineAppSettingsAlt}
              />
            ) : null,
          userAccessControl(permissions, 'read', ['homeComponents']) ? (
            <Resource
              name='homeComponents'
              options={{ label: 'Home' }}
              {...HomeComponents}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['more']) ? (
            <Resource name='more' options={{ label: 'More' }} {...More} />
          ) : null,
          userAccessControl(permissions, 'read', ['more']) ? (
            <Resource
              name='on_boarding'
              options={{ label: 'Onboarding' }}
              {...Onboarding}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['interests']) ? (
            <Resource
              name='interests'
              options={{ label: 'Interests' }}
              {...Interests}
            />
          ) : null,
          //******************* CHANNELS SECTION *******************
          userAccessControl(permissions, 'read', ['notifications']) ? (
            <Resource
              name='CHANNELS'
              options={{ isTitle: true }}
              icon={FaBullhorn}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['notifications']) ? (
            <Resource
              name='notifications'
              options={{ label: 'Push Notifications' }}
              {...Notifications}
            />
          ) : null,
          //******************* SETTINGS SECTION *******************
          userAccessControl(permissions, 'read', ['admins']) ? (
            <Resource name='SETTINGS' options={{ isTitle: true }} />
          ) : null,
          userAccessControl(permissions, 'read', ['admins']) ? (
            <Resource name='admins' options={{ label: 'Users' }} {...Admins} />
          ) : null,
          userAccessControl(permissions, 'read', ['settings']) ? (
            <Resource
              name='festivals'
              options={{ label: 'Festival Settings' }}
              {...Info}
            />
          ) : null,
        ]}
      </Admin>
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
}

export default App;
