import { ReferenceArrayInput, required } from 'react-admin';
import InputAutocomplete from './InputAutocomplete';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import { Box, Typography } from '@mui/material';
import { AiOutlineWarning } from 'react-icons/ai';


export const MessageInput = (props) => {
  const { 
    type = "warning",
    text,
    noIcon,
  } = props;

  const styles = {
    "warning" : {
      boxStyles: {
        'background-color': 'rgba(255, 212, 62, 0.15)',
        'border-radius': '12px',
        'width': '100%',
        'margin-bottom': '20px',
        'padding': '15px',
        'display': 'flex',
        'flex-direction': 'row',
        'justify-content': 'space-between',
        'align-items': 'center'
      },
      textStyle: {
        'color': '#EBB803',
        'margin-left': !noIcon ? '30px' : 0,
        'font-size': '12px', 
        'font-weight': '700', 
        'width': '100%'
      },
      icon: <AiOutlineWarning color="#EBB803" size={30} />
    },
    "error" : {
      boxStyles: {
        'background-color': 'rgba(237, 12, 19, 0.45)',
        'border-radius': '12px',
        'width': '100%',
        'margin-bottom': '20px',
        'padding': '15px',
        'display': 'flex',
        'flex-direction': 'row',
        'justify-content': 'space-between',
        'align-items': 'center'
      },
      textStyle: {
        'color': '#ff0028',
        'margin-left': !noIcon ? '30px' : 0,
        'font-size': '12px', 
        'font-weight': '700', 
        'width': '100%'
      },
      icon: <IoCloseCircleOutline color="#ff0028" size={30}/>
    }
  }
      
  return(
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%'}}>
      <Box style={styles[type].boxStyles}>
        {!noIcon && React.cloneElement(styles[type].icon)}
        <Typography style={styles[type].textStyle}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};