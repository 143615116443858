import { Box, CircularProgress, Typography } from '@mui/material';
import { Spinner } from '../Spinner';
import { Fragment } from 'react';



export const ImportTemplate = (props) => {
  const { loading, success, title, description, successProps, errorProps, component, error } = props;


  return (
    <Box sx={{
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '0px 30px',
      gap: '20px'
    }}>
      {loading ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          gap: '30px'
        }}>
          <Typography sx={{ fontSize: '1.4em', fontWeight: '600' }}>Uploading Data...</Typography>
          <Typography sx={{ fontSize: '.9em' }}>Uploading your data, you will recieve your results shortly</Typography>
          <Box sx={{
            position:'relative',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'inherit',
            minHeight: '200px'
          }}>
            <CircularProgress size="100px" />
          </Box>
        </Box>
      ) : (
        <Fragment>
          <Typography sx={{ fontSize: '1.4em', fontWeight: '600' }}>{success ? successProps?.title : error ? errorProps?.title : title}</Typography>
          <Typography sx={{ fontSize: '.9em' }}>{success ? successProps?.description : error ? errorProps?.description : description}</Typography>
          {success ? successProps?.component : error ? errorProps?.component : component}
        </Fragment>
      )}
    </Box>
  );
};