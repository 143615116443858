import { Box, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { strCapitalize } from '../../../helpers/strCapitilize';


export const ChoicesButtons = (props) => {
  const { key = 'name', optionLabel = 'name', capitalize, source, onSelect, choices = [], isMulti } = props;
  const [selection, setSelection] = useState([]);

  const handleSelect = (source, item) => {
    let value = item[key].toLowerCase();
    if(capitalize) {
      value = strCapitalize(value);
    }
    if(isMulti){
      let arr = [...selection];
      if(arr?.find(it => it === value)) {
        arr = arr.filter(it => it !== value);
      } else {
        arr = [...arr, value];
      }
      setSelection(arr);
      onSelect(source, arr);
    } else {
      onSelect(source, value);
      setSelection(value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justiyContent: 'flex-start',
        width: 'inherit',
      }}
      key={source}
    >
      {choices?.map((item, index) => (
        <Button
          key={`${index}-method`}
          variant="none"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textTransform: 'none',
            width: 'inherit',
            backgroundColor: !isMulti && item === selection ? '#a9a9a9' : isMulti && selection?.find(it => item[key].toLowerCase() === it) ? '#a9a9a9' : 'none',
           
          }}
          onClick={() => handleSelect(source, item)}
        >
          {item[optionLabel]}
        </Button>
      ))}
    </Box>
  );
};