import { useInput } from 'react-admin';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputAddTextInputs } from '../../../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const createObj = (arr) => {
  if(arr) {
    return arr?.map((item, i) => {
      return {key: i, value: item};
    });
  } else {
    return [{ key: 0, value: {id: 0, text: {name: ''}}}];
  }
};

// Format el objeto para pasar al input
const formatObj = (arr) => {
  return arr?.map(item => item.value);
};


export const InputAddCategories = ({value, title, onChange}) => {
  

  const handleChange = (e,v) => onChange(formatObj(e));

  const disable = (item) => {
    
    return item?.value?.text?.reference_count > 0 ? true : false;
  };

  return (
    <>
      <InputTitle>{title}</InputTitle>
      <Box sx={{
        // backgroundColor: '#f0f0f0',
        // border: '1px solid #d8d8d8',
        width: '95%',
        height: 'auto',
        
        borderRadius: '0px 0px 12px 12px'

        // marginBottom: '30px'
      }}>
        <InputAddTextInputs
          direction="row"
          addSx={{
            textAlign:'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems:'center',
            justifyContent:'flex-start',
            fontSize: '.9em',
            paddingY: 1,

          }}
          element={
            <CustomTextField />
          }
          addText={'+ Add Category'}
          removeAside
          addBelow
          noPadding
          values={createObj(value)}
          initialNumber={value?.length}
          setValues={handleChange}
          limit={20} // No mas, hablar con rochi si se quiere agregar mas
          // addIcon={<IoMdAddCircle color={mainColor} size={'25px'} />}
          sx={{
            '*': {
              gap: 0
            }
          }}
          disableRemove={disable}
          noDisableRemove
        />
      </Box>
    </>
  );
};

export const CustomTextField = (props) => {
  const {
    deleteHandle, 
    children, 
    newValues, 
    handleChange,
    index,
    ...rest
  } = props;
  const [value, setValue] = useState(newValues[index]?.value ?? '');

  useEffect(() => {
    if(value !== '') {
      let valueUpdated = false;
      const updatedValues = newValues.map((item) => {
        if (item.key === index) {
          valueUpdated = true;
          return { ...item, value: value };
        }
        return item;
      });
      if (!valueUpdated) {
        updatedValues.push({ key: index, value: value });
      }
      handleChange(updatedValues);
      return() => {
        valueUpdated = false;
      };
    }
  },[value]);

  const handleCategory = (e) => {
    let val = e.target.value;
    setValue({id: index, text: {name: val}});
  };

  return (
    <Box sx={{
      // borderBottom: '1px solid #d8d8d8',
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }}>
      <TextField 
        sx={{
          marginX: 2,
          borderBottom: 'none',
          width: '80%'
        }}
        value={value?.text?.name} 
        placeholder='Write here'
        
        size="medium"
        variant="standard" 
        onChange={handleCategory}
        InputProps={{
          sx: {
            fontSize: '.8em'
          },
          // startAdornment: <AccountCircle />, // <== adjusted this
          endAdornment: (
            <Tooltip title="Number of current categories in use.">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Typography sx={{fontSize: '.9em', fontWeight: '600'}}>{value.text?.reference_count ?? '0'}</Typography>
              </div>
            </Tooltip>
          ),
          disableUnderline: true, // <== added this
        }} 
        {...props} 
      />
    </Box>
  );
};