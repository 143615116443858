import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { CustomDateField } from './DatePicker';
import RemoveIcon from '@mui/icons-material/Remove';
import { grey } from '@mui/material/colors';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import { useQuery } from 'react-query';
import { useDataProvider, useGetList } from 'react-admin';
import { ChoicesButtons } from './ChoicesButtons';
import TuneIcon from '@mui/icons-material/Tune';
import { ChoicesDropdown } from './FilterComponents';
import { formFilters } from '../resourceFilters';
import { useSelector } from 'react-redux';



export const AddFilter = (props) => {
  const {
    filters,
    setFilters,
    resource
  } = props;
  const palette = useSelector((state) => state.palette.palette);
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  useOnClickOutside(ref, () => {
    setOpen(false);
    setOpenFilter(null);
  });

  const handleClick = (e) => {
    if (openFilter === e) return setOpenFilter(null);
    setOpenFilter(e);
  };
  const handleSelect = (e, value) => {
    console.log(e);
    if(Array.isArray(value)) {
      setFilters({ ...filters, [e]: value.map(item => encodeURIComponent(item)) });
    } else {
      setFilters({ ...filters, [e]: encodeURIComponent(value) });

    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
      }}
      key="add-filter"
    >
      {/* <Button onClick={() => setOpen(true)}>Añadir Filtro</Button> */}
      <Button 
        variant="contained"
        sx={{
          boxShadow: 'none',
          width: '45px',
          height: '45px', 
          backgroundColor: 'rgb(232, 232, 232)',
          '&:hover':{
            backgroundColor: palette?.primary,
          }
        }} onClick={() => setOpen(true)}><TuneIcon sx={{color: 'rgb(150, 150, 150)',
          '&:hover':{
            // backgroundColor: palette?.primary,
            color: '#fff'
          }
        }}/></Button>
      {open && (
        <Box
          ref={ref}
          sx={{
            position: 'absolute',
            top: '100%',
            right: '0%',
            // transform: "translate(-50%, 25%)",
            padding: '10px',
            backgroundColor: '#fff',
            zIndex: 99999,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            minWidth: '300px',
            gap: '20px',
            border: '1px solid #acacac',
            borderRadius: '12px',
          }}
        >
          {formFilters[resource].map((item, index) => (
            <Box
              sx={{
                width: 'inherit',
                transition: 'height 0.4s ease-in-out, opacity 0.5s ease-in-out',
              }}
              key={`filter-${index}`}
            >
              <Button
                variant="none"
                value={item.key}
                onClick={() => handleClick(item.key)}
                sx={{
                  color: 'black',
                  // width: "inherit",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  backgroundColor: openFilter === item.key ? grey[200] : '#fff',
                }}
                endIcon={openFilter !== item.key ? <AddIcon /> : <RemoveIcon />}
              >
                <Typography sx={{ fontSize: '.9em', fontWeight: '600'}}>
                  {item.value}
                </Typography>
              </Button>

              <Box
                sx={{
                  height: openFilter === item.key ? 'inherit' : '0px',

                  opacity: openFilter === item.key ? 1 : 0,
                  width: 'inherit',
                  backgroundColor: '#f6f6f6',
                  transition:
                    'height 0.4s ease-in-out, opacity 0.5s ease-in-out',
                  overflow: 'hidden',
                }}
              >
                <ChoicesDropdown {...props} onSelect={handleSelect} source={item.key} {...item} />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
