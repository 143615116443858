import axios from 'axios';
import { useQuery } from 'react-query';

const axiosRequest = (url, method = 'GET') => axios({
  url: process.env.REACT_APP_BASE_PATH + url,
  method: method,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'content-range',
    Authorization: 'Bearer ' + localStorage.getItem('auth'),
  }
});

export const useAppMetrics = (url, options = {}) => {
  console.log(url);
  const { onSuccess = () => {}, onFailure = () => {}, enabled = true } = options;

  const { data: { data: data } = {}, isFetching, refetch } = useQuery([`appMetrics-${url}`], () => {
    return axiosRequest(`/metrics/${url}`);
  }, {
    onSuccess: (res) => onSuccess(res),
    onFailure: (err) => onFailure(err),
    enabled: enabled
  });

  return {
    data,
    isFetching,

  };
};
