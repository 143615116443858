import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import { strCapitalize } from '../../../../helpers/strCapitilize';

export function shotWeekdayFunc(year, month, day) {
  return strCapitalize(
    new Date(year, month, day).toLocaleDateString('en-EN', {
      weekday: 'short',
    })
  );
}

export function loopedValue(value, lastValue, boolean, arr = []) {
  if (!boolean) {
    if (value + 1 <= lastValue) {
      return loopedValue(value + 1, lastValue, boolean, [value, ...arr]);
    } else {
      return [value, ...arr];
    }
  } else {
    if (value !== lastValue) {
      return loopedValue(value - 1, lastValue, boolean, [...arr, value]);
    } else {
      return [...arr, value].reverse();
    }
  }
}

export const getDate = (value) => {
  if (!value || typeof value !== 'object' || !value?.from || !value?.until)
    return;

  let from = value.from.split('-');
  let until = value.until.split('-');
  return {
    first: new Date(from[0], from[1], from[2]).getDate(),
    last: new Date(until[0], until[1], until[2]).getDate(),
  };
};

export function parseValuesToFilter(year, month, arr) {
  if (arr[0] < arr[arr.length - 1]) {
    return {
      from: dateFormatter(year, month, arr[0]),
      until: dateFormatter(year, month, arr[arr.length - 1]),
    };
  } else {
    return {
      from: dateFormatter(year, month, arr[arr.length - 1]),
      until: dateFormatter(year, month, arr[0]),
    };
  }
}

export function parseValueToFilterDays (year, month, arr, key ) {
  console.log(key);
  let value = arr?.map((item) => dateFormatter(year, month, item)).sort((a,b) => a > b ? +1 : -1);

  return { [key]: value };
}

export function formatFilterToValues(first, second) {
  return loopedValue(second, first, Boolean(second > first));
}

export function multiSelectValues(prev, newValue) {
  let value = parseInt(newValue);
  if (prev.length === 0) return [value];
  let lastValue = parseInt(prev[prev.length - 1]);

  return loopedValue(lastValue, value, Boolean(lastValue > value));
}

export function handleBorderRadius(arr, index) {
  let first = arr[0];
  let last = arr[arr.length - 1];
  if (arr.length === 1) return '12px';
  if (first < last) {
    if (arr.includes(index) && index === first) return '12px 0px 0px 12px';
    if (arr.includes(index) && index !== first && index !== last) return '0px';
    if (arr.includes(index) && index === last) return '0px 12px 12px 0px';
    return '12px';
  } else {
    if (arr.includes(index) && index === first) return '0px 12px 12px 0px';
    if (arr.includes(index) && index !== first && index !== last) return '0px';
    if (arr.includes(index) && index === last) return '12px 0px 0px 12px';
    return '12px';
  }
}

export function setNewFilters(oldFilters, newFilters, key) {

  let obj = oldFilters;
  let arr = Object.keys(obj);

  let newArr = Object.keys(newFilters);
  for (let i = 0; i <= arr.length; i++) {
    for (let j = 0; j < newArr.length; j++) {
      if (arr[i] === newArr[j]) {
        obj[arr[i]] = newFilters[newArr[j]];
      } else {
        obj = { ...obj, [newArr[j]]: newFilters[newArr[j]] };
      }
    }
  }

  return {
    [key]: obj[key]
  };
  // return {
  //   ['start_date']: obj.from,
  //   ['end_date']: obj.until
  // };
}

export const getFestivalMonth = (arr, month) => {
  return arr.reduce((a, b) => {
    if((new Number(b.split('-')[1]) - 1) === month) {
      return [...a, b];
    } 
    return [...a];
    
  },[]);
};

export const excludeDay = (arr, day) => {
  return arr.find(item => {
    let festDay = parseInt(item.split('-')[2]);
    if(festDay === day) return item;
  });
};

export const getMonths = (arr) => {
  return arr.reduce((a, b) => {
    let month = `${b.split('-')[1]}/${b.split('-')[0]}`;
    if(a.find(item => item === month)){
      return [...a];
    }
    return [...a, month];
  },[]);
};

export const selectMonth = (arr, initial, boolean) => {
  let months = getMonths(arr);
  let month = months.findIndex(item => {
    return parseInt(item.split('/')[0]) === initial;
  });

  if(month === -1) return months[initial];
  if(boolean){
    if(month + 1 < months.length){
      return months[month + 1];
    }
    return months[month];
  } else {
    if(month - 1 >= 0){
      return months[month - 1];
    }
    return months[month];
  }
};