import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useInput, useGetList, useStore, useRecordContext, useResourceContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { Chip, Grid, Tooltip, FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import { useUtcFormat } from '../../../helpers/date';
import { useSelector } from 'react-redux';
import { useRequiredSchema } from '../../../Hooks/useRequiredSchema';
import { MessageInput } from '../../InputsForm/MessageAutocomplete';

const InputCategoryPills = (props) => {
  const {
    title,
    source,
    reference,
    subtitle,
    filter,
    isEdit,
    required,
    isArray,
    validate = [],
    isLocation
  } = props;
  const { isRequiredSchema } = useRequiredSchema();
  const [_, setStoredLocations] = useStore('locations');
  const record = useRecordContext();
  const { getValues } = useFormContext();
  const [categoryPicked, setCategoryPicked] = useState(record?.category ? record?.category : null);
  const {
    field: openField,
    fieldState:{error,invalid}
  } = useInput({
    source,
    validate: [...isRequiredSchema(source), ...validate],
  });
  const errorMessage = error ? error?.message?.split('@@react-admin@@')[1]?.replace(/"/g, '') : '';

  const {
    data: choices,
    error:errorList,
    loading,
  } = useGetList(
    `${reference}`,
    {
      pagination: {
        perPage: 100,
        page: 1,
      },
      filter: {
        ...filter,
      },
      limit: 100,
    },
    {
      onSuccess: ({ data }) => {
        setStoredLocations(data);
        if(isLocation){
          if(record?.main_location){
            setCategoryPicked(
              data?.find((item) => item.id === record?.main_location)?.name,
            );
          }
          else{
            setCategoryPicked(
              data?.find((item) => item.id === record?.location_id)?.name,
            );
          }
        }
      },
    },
  );

  const valores = getValues();
  const handlerSetValue = (value) => {
    let valor;

    if (isArray) {
      if (!categoryPicked?.includes(value?.text)) {
        valor = value;
        setCategoryPicked([...categoryPicked, valor?.text]);
        openField.onChange([...categoryPicked, valor?.text]);
      } else {
        valor = value;
        setCategoryPicked(
          categoryPicked.filter((item) => item !== value?.text),
        );
        openField.onChange(
          categoryPicked.filter((item) => item !== value?.text),
        );
      }
    } else {
      if (reference === 'events' || reference === 'talents') {
        valor = value?.value ?? '';
      } else if (
        reference === 'admins/manager_roles' ||
        reference === 'talents/categories' ||
        reference === 'events/categories' ||
        reference === 'faqs/categories'
      ) {
        valor = value?.text ?? '';
      } else if (reference === 'locations') {
        valor = value?.name ?? '';
        if (categoryPicked !== valor) {
          console.log(valor);
          setCategoryPicked(valor);
          openField.onChange(value.id);
          return;
        } else {
          setCategoryPicked('');
          openField.onChange('');
          return;
        }
      } else {
        valor = value?.id ?? '';
      }
      setCategoryPicked(valor);
      openField.onChange(valor);
    }
  };

  useEffect(() => {
    if (
      reference === 'events' ||
      reference === 'events/categories' ||
      reference === 'faqs/categories' || reference === 'talents' || reference === 'talents/categories'
    ) {
      setCategoryPicked(valores?.category);
    } else if (reference === 'admins/manager_roles' && isEdit) {
      setCategoryPicked(valores?.manager_role && valores?.manager_role[0]);
    } else if (reference === 'talents/tags') {
      if (valores?.tags !== undefined) {
        setCategoryPicked(valores?.tags);
      } else {
        setCategoryPicked([]);
      }
    } else {
      setCategoryPicked(record?.stage?.name);
    }
  }, [record]);

  return (
    <div key={source}>
      {title && <InputTitle required={required}>{title}</InputTitle>}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 10,
          marginBottom: '1.8em',
          ...props.sx,
        }}
      >
        {loading ? (
          <div>Loading...</div>
        ) : errorList ? (
          <div>Error</div>
        ) : isArray ? (
          choices &&
          choices.map((choice, index) => (
            <ChipComponent
              choice={choice}
              index={index}
              categoryPicked={categoryPicked}
              handlerSetValue={handlerSetValue}
              choices={choices}
              source={source}
              {...props}
            />
          ))
        ) : choices?.length === 0 && isLocation ? (
          <MessageInput text={'No locations have been found, to schedule a location you should create a location'} />
        ) : (
          choices &&
          choices.map((choice, index) => (
            <ChipComponent
              choice={choice}
              index={index}
              categoryPicked={categoryPicked}
              handlerSetValue={handlerSetValue}
              choices={choices}
              source={source}
              {...props}
            />
          ))
          
        )}
      </div>
      {invalid && <FormHelperText sx={{marginBottom: '5px'}} error>{errorMessage}</FormHelperText>}
    </div>
  );
};

export default InputCategoryPills;

export const ChipComponent = (props) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const {
    optionText = 'text',
    optionValue = 'text',
    categoryPicked,
    choice,
    index,
    handlerSetValue,
    source
  } = props;

  console.log(palette.fontColor);

  return (
    <TooltipComponent location={choice} source={source}>
      <Chip
        aria-describedby={choice.name === categoryPicked}
        id={`hover-open-times-${index}`}
        aria-haspopup="true"
        sx={{
          position: 'relative',
          width: 'auto',
          padding: '10px',
          color: categoryPicked === choice?.[optionValue] ? palette.fontColor : '#000000',
          fontSize: '13px',
          fontWeight: '400',
          cursor: 'pointer',
          backgroundColor:
            categoryPicked === choice?.[optionValue]
              ? palette?.primary
              : palette?.input_background,
          ':hover': {
            backgroundColor: palette?.primary,
            color: 'white',
          },
          '& > *': {
            pointerEvents: 'none',
          },
        }}
        key={index}
        onClick={() => handlerSetValue(choice)}
        label={choice[optionText]}
      />
    </TooltipComponent>
  );
};

const TooltipComponent = ({ location, children, source }) => {
  const { formatFestivalTime } = useUtcFormat();
  const resource = useResourceContext();
  const GridComponent = ({ location }) => {
    const openTimes = location.open_times;
    if (!openTimes || openTimes.length === 0)
      return 'No open times found for this location.';
    return (
      <Grid container rowGap={2}>
        {location &&
          openTimes?.map((item, index) => {
            return (
              <Grid item xs={12}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  <span>{dayjs(item.open).format('ddd - DD/MM')}</span>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                      Open:{' '}
                      <span>
                        {dayjs(formatFestivalTime(item.open)).format('HH:mm')}
                      </span>
                    </span>
                    <span>
                      Close:{' '}
                      <span>
                        {dayjs(item.close).isSame(item.open, 'date')
                          ? dayjs(formatFestivalTime(item.close)).format(
                            'HH:mm',
                          )
                          : dayjs(formatFestivalTime(item.close)).format(
                            'HH:mm',
                          )}
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <Tooltip
      arrow
      placement="top"
      slotProps={{
        tooltip: {
          sx: {
            fontSize: '.8em',
            fontWeight: '600',
          },
        },
      }}
      title={resource === 'events' && source === 'location_id' ? <GridComponent location={location} />: ''}
    >
      {children}
    </Tooltip>
  );
};
