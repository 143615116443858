import { Box } from '@mui/material';
import { DashboardCard } from './DashboardCard';
import ChartBarComponent from './ChartBarComponent';
import { applyOthers } from '../functions/functions';


export const PopularityMetrics = ({ data }) => {


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '30px'
    }} mt={4}>
      <DashboardCard sx={{maxWidth: '40%'}} title="Most Popular Events">
        <ChartBarComponent labels={applyOthers(data?.events?.favs?.map(item => item.name), 'string')} data={applyOthers(data?.events?.favs?.map(item => item.count))} />
      </DashboardCard>
      <DashboardCard sx={{maxWidth: '40%'}} title="Most Popular Artists">
        <ChartBarComponent labels={applyOthers(data?.talents?.favs?.map(item => item.name), 'string')} data={applyOthers(data?.talents?.favs?.map(item => item.count))} />
      </DashboardCard>
      <DashboardCard sx={{maxWidth: '40%'}} title="Most Popular Posts">
        <ChartBarComponent labels={applyOthers(data?.articles?.favs?.map(item => item.name), 'string')} data={applyOthers(data?.articles?.favs?.map(item => item.count))} />
      </DashboardCard>
    </Box>
  );
};