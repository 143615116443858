import React, { useEffect } from 'react';
import {
  Layout,
  usePermissions,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { AppbarCustom, MyAppBar } from '../AppbarCustom/AppbarCustom';
import MenuCustom from './MenuCustom';
import ErrorCustom from '../ErrorCustom/ErrorCustom';
const LayoutCustom = (props) => {
  const redirect = useRedirect();
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  /* Esto pasa porque el primer resource que existe es el de un menuParent en este caso 'Talent'.
   React admin no lee el resoruce que no tenga una list, por lo que no se peude acceder por hook y no renderiza nada siendo que no contiene nada pero igualmente mantiene la url.
   Esta interaccion solo ocurre cuando entras por primera vez al backoffice, y es necesario realizar un redirect al siguiente resource que si contiene una list (en este caso 'talents')
 */
  // useEffect(()=>{
  //   let wrongHref = window.location.href.split('/');
  //   if(wrongHref[wrongHref.length - 1] === 'Talent') return redirect('list', 'talents');
  // },[]);

  // Redirect de la homepage a la lista de talentos

  useEffect(() => {
    let wrongHref = window.location.href.split('/');
    if (wrongHref[4] === 'CONTENT') return redirect('talents');
  }, []);

  return (
    <>
      <Layout
        appBarAlwaysOn={true}
        sx={{
          '& .RaSidebar-fixed': {
            borderRight: '1px solid',
            borderColor: '#cdcdcd',
            backgroundColor: 'rgb(250 250 250)',
          },
          '& .RaLayout-appFrame': {
            marginTop: '0px',
          },
          '&  .RaLayout-content': {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
          '&  .MuiDrawer-docked': {
            borderRight: '1px solid',
            borderColor: '#cdcdcd',
            backgroundColor: 'rgb(246, 246, 246)'
          },
        }}
        {...props}
        error={ErrorCustom}
        menu={MenuCustom}
        appBar={AppbarCustom}
      />
    </>
  );
};

export default LayoutCustom;
