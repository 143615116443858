import React from 'react';
import { BooleanInput } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { Box } from '@mui/material';
import { switchStyleIos } from '../StyledComponents/Inputs';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema';
const InputBoolean = ({
  isColumn,
  source,
  title,
  titleTransform,
  subtitle,
  invert,
  validate = [],
  ...rest
}) => {
  const { isRequiredSchema } = useRequiredSchema();

  return (
    <Box
      key={rest.source}
      sx={{
        display: isColumn ? 'flex' : 'grid',
        flexDirection: isColumn ? 'column' : null,
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...rest.sx,
      }}
    >
      <Box fullWidth>
        {title && (
          <InputTitle
            textTransform={titleTransform}
            sx={{ marginBottom: isColumn ? '5px' : null }}
            value={title}
          />
        )}
        {subtitle && (
          <InputSubtitle sx={{ marginBottom: '20px' }}>
            {subtitle}
          </InputSubtitle>
        )}
      </Box>
      <BooleanInput
        options={{
          sx: switchStyleIos,
        }}
        parse={(e) => (invert ? !e : e)}
        format={(e) => (invert ? !e : e)}
        size='medium'
        sx={{
          alignItems: 'center',
          ...rest.sx,
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
        }}
        source={source}
        validate={[...isRequiredSchema(source), ...validate]}
        {...rest}
      />
    </Box>
  );
};

export default InputBoolean;
