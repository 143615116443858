import { Box, Button } from '@mui/material';
import React, { cloneElement, useMemo, useState } from 'react';
import { YearSelector } from './YearSelector';
import { MonthSelector } from './MonthSelector';
import { DaySelector } from './DaySelector';
import { setNewFilters } from './functions';

export const ConfirmButtons = (props) => {
  const { type, filters, setFilters, value  } = props;
  // const [filter, setFilter] = useState({});
  const Children = useMemo(() => {
    switch (!!type) {
    case type === 'year':
      return <YearSelector />;
    case type === 'month':
      return <MonthSelector />;
    case type === 'day':
      return <DaySelector />;
    }
  }, [type]);

  const handleAccept = (filter) => {
    setFilters(setNewFilters(filters, filter, value));
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        gap: '20px',
        width: '100%',
      }}
    >
      {cloneElement(
        Children,
        {
          // setFilters: setFilter,
          // filters: filter,
          handleAccept: handleAccept,
          ...props
        },
        null
      )}
    </Box>
  );
};
