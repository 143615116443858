import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCustomToasty } from '../../Hooks/useCustomToasty';



export const useImportPost = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    success: false
  });
  const customToast = useCustomToasty();

  const token = localStorage.getItem('auth');
  const url= process.env.REACT_APP_BASE_PATH;
  const refetch = async (resource, file, options) => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'content-range',
      'Contet-Type': 'multipart/form-data',
    };
    
    const formData = new FormData();
    formData.append('file', file);
    console.log(file);
    setStatus(prev => ({...prev, loading:true}));
    axios({
      url: `${url}/${resource}/uploadfile`, 
      method: 'POST',
      data: formData,
      headers: {
        ...headers,
        'Authorization': 'Bearer ' + token,
      }
    }).then((res) =>{
      setStatus(prev => ({...prev, success: true, loading:false}));
      // customToast('success', 'Success');
      options?.onSuccess && options.onSuccess();
    }).catch((err) =>{
      setStatus(prev => ({...prev, error: true, loading:false}));
      // customToast('error', 'error');
      options?.onError && options.onError();

    });
  };

  useEffect(() => {
    return () => {
      setStatus({
        loading: false,
        error: false,
        success: false
      }) 
    }
  },[])

  return {
    refetch,
    ...status,
  };
};