import { useMemo, useState } from 'react';
import { Box, Divider, FormControlLabel, FormGroup, Skeleton, Switch } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeLineDays } from './TimeLineDays';
import { useSelector } from 'react-redux';
import StartDates from '../../StartDates/StartDates';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { switchStyleIos } from '../../StyledComponents/Inputs';
import { allDay, checkAllDates, defaultAll, handleFormatParse, handleHours } from './functions';
import { useInput, useRecordContext } from 'react-admin';
import { useUtcFormat } from '../../../helpers/date';

export const InputSelectTimes = ({ source }) => {
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();

  const record = useRecordContext();
  const days = useSelector((state) => state.data.info.days);
  const [wholeFestival, setWholeFestival] = useState(record ? checkAllDates(days, handleFormatParse(record[source], formatFestivalTime)) : false);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {days ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection:'column',
            width: '100%',
            height: 'auto',
            gap: '10px'
          }}
        >
          <OpenWholeFestival wholeFestival={wholeFestival} onChange={setWholeFestival} source={source} days={days} />

          { !wholeFestival && (
            <TimeLineDays
              source={source}
              days={days}
            />
          )}
        </Box>
      ) : (
        <>
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
        </>
      )}
    </LocalizationProvider>
  );
};


export const InputSelectStartDates = ({ source }) => {
  const days = useSelector((state) => state.data.info.days);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {days ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection:'column',
            width: '100%',
            height: 'auto',
            gap: '10px'
          }}
        >
          <StartDates source={source} days={days} />
       
        </Box>
      ) : (
        <>
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
        </>
      )}
    </LocalizationProvider>
  );
};

export const OpenWholeFestival = ({ wholeFestival, onChange, source, days }) => {
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  const record = useRecordContext();
  const { field } = useInput({ source,
    format: (e) => handleFormatParse(e, formatFestivalTime),
    parse: (e) => handleFormatParse(e, parseFestivalTime),
  });

  const handleSetAll = (e) => {
    let boolean = e.target.checked;
    onChange(boolean);
    field.onChange(defaultAll(boolean, days, record?.[source]));

  };

  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px', height:'80px'}}>
      <Box>
        <InputTitle>Always open</InputTitle>
        <InputSubtitle>You can set this location to be open 24hs throughout the festival.</InputSubtitle>
      </Box>
      <FormGroup sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px'}}>
        <FormControlLabel control={<Switch 
          checked={wholeFestival}
          onClick={handleSetAll}
          sx={switchStyleIos}
        />} label="" />
      </FormGroup>
    </Box>
  );
};